import * as actionTypes from './types';
import fetchData from '../services';
import { saveAs } from 'file-saver';

export const listSubscription = (id) => {
    let obj = {
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
    }
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let response = fetchData({ url: '/user/planList', method: 'POST', data: obj });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_LIST,
                    payload: res.response.planList,
                    userSubscribedPlan: res.response.userPlan,
                    planSubscribedDate: (res.response.subscribedDateObj && Object.keys(res.response.subscribedDateObj).length > 0) ? res.response.subscribedDateObj : {},
                    subscriptionExpired: (res.response.expireDateObj && Object.keys(res.response.expireDateObj).length > 0) ? res.response.expireDateObj : {}
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const clearSubscription = (id) => {
    return {
        type: actionTypes.CLEAR_SUBSCRIPTION,
        payload: []
    }
}

export const addSubscriptionFormData = (subscriptionData) => {
    return {
        type: actionTypes.SUBSCRIPTION_ADD_VIEW,
        payload: subscriptionData
    }
};
export const pdfmodel = (message) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: message, alignment: "centre" }
        });
    }
};

export const editView = (subscriptionId) => {
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/getPlanbyId', method: 'POST', data: { planId: subscriptionId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_EDIT_VIEW,
                    payload: res.response
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const editSubscriptionFormData = (subscriptionData) => {
    return {
        type: actionTypes.SUBSCRIPTION_EDIT_VIEW,
        payload: subscriptionData
    }
};
export const setPlanPeriod = () => {
    return {
        type: actionTypes.SUBSCRIPTION_PERIOD,
    }
};

export const showSubscribe = (item, renewalCycle) => {
    return {
        type: actionTypes.SHOW_SUBSCRIPTION,
        subscriptedPlan: item ? item : {},
        renewalCycle: renewalCycle ? renewalCycle : "",
        payload: {}
    }
}

export const addPaymentFormData = (subscriptionData) => {
    return {
        type: actionTypes.PAYMENT_ADD_VIEW,
        payload: subscriptionData
    }
};

export const clearSubscriptionFormData = (subscriptionData) => {
    return {
        type: actionTypes.CLEAR_SUBSCRIPTION_ADD_VIEW,
        payload: {}
    }
};

export const editSubscription = (subscriptionData, props) => {
    let formData = {
        planId: subscriptionData._id,
        planName: subscriptionData.name,
        planType: subscriptionData.planType,
        licenseFee: subscriptionData.licenseFee,
        licensePeriod: subscriptionData.licensePeriod,
        monthlyAmount: subscriptionData.monthlyAmount,
        yearlyAmount: subscriptionData.yearlyAmount,
        status: subscriptionData.status,
        folders: subscriptionData.folders
        // renewalCycle: subscriptionData.renewalCycle,
        // file: subscriptionData.file,
    }
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/editplan', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Subscription Edited Successfully.', alignment: "centre" }
                });
                props.history.push('/subscriptions');
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const addSubscription = (subscriptionData, props) => {
    let formData = {
        planName: subscriptionData.name,
        planType: subscriptionData.planType,
        licenseFee: subscriptionData.licenseFee,
        licensePeriod: subscriptionData.licensePeriod,
        monthlyAmount: subscriptionData.monthlyAmount,
        yearlyAmount: subscriptionData.yearlyAmount,
        status: subscriptionData.status,
        folders: subscriptionData && subscriptionData.folders ? subscriptionData.folders : []
        // renewalCycle: subscriptionData.renewalCycle,
        // file: subscriptionData.file,
    }
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/addplan', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.CLEAR_SUBSCRIPTION_ADD_VIEW,
                    payload: {}
                });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Subscription Added Successfully.', alignment: "centre" }
                });
                props.history.push('/subscriptions');
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const subscriptionDeleteModal = (id) => {
    return {
        type: actionTypes.SUBSCRIPTION_DELETE_MODAL,
        subscriptionId: id ? id : ""
    }
}

export const deleteSubscription = (subscriptionId) => {
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/deletePlan', method: 'POST', data: { planId: subscriptionId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_DELETE_MODAL,
                    subscriptionId: ""
                });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Subscription Deleted Successfully.', alignment: "centre" }
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const planSubscription = (props) => {
    let plan = props && props.subscriptedPlan ? props.subscriptedPlan : "";
    let payment = props && props.paymentData ? props.paymentData : "";
    let formData = {
        userId: sessionStorage.getItem('userId'),
        amount: props.total,
        token: sessionStorage.getItem('token'),
        payment: JSON.stringify(payment),
        plan: JSON.stringify(plan),
        selectedPackage: JSON.stringify(props.selected_package)
        // plan: JSON.stringify(plan)
        // planId: plan._id,
        // email: payment.email,
        // renewalCycle: plan.licensePeriod,
        // renewalCycle: plan.renewalCycle,
        // planType: plan.planType,
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/subscribeplan', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                sessionStorage.removeItem('subscriptionPay');
                sessionStorage.removeItem('addonDetails');
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Subscription Added Successfully.', alignment: "centre" }
                });
                dispatch({
                    type: actionTypes.SUBSCRIPTION_ADD_VIEW,
                    payload: {}
                });
                dispatch({
                    type: actionTypes.REMOVE_SUBSCRIPTION_PLAN_RENEWAL,
                    payload: {}
                });
                dispatch({
                    type: actionTypes.SELECT_ADDON_PACKAGE,
                    payload: []
                });
                props.history.push("/subscriptions");
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const afterPaymentSuccess = (data) => {
    return (dispatch) => {
        let userId = String(sessionStorage.getItem('userId'));
        let paymentDetails = JSON.stringify(data.paymentData);
        let selectedPackages = (sessionStorage.getItem('addonDetails'));
        let employeePlan = sessionStorage.getItem('employeePlan');
        let subscription_id = data.subscription_id && data.subscription_id !== undefined ? data.subscription_id : '';
        let document_licence_type = data.document_licence_type && data.document_licence_type !== undefined ? data.document_licence_type : '';
        let request = fetchData({ url: `/user/subscribeplan/success?userId=${(userId)}&paymentDetails=${paymentDetails}&selectedPackages=${selectedPackages}&orderId=${String(data.orderId)}&subscription_id=${subscription_id}&employeePlan=${employeePlan}&document_licence_type=${document_licence_type}`, method: 'GET' });
        request.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                sessionStorage.removeItem('subscriptionPay');
                sessionStorage.removeItem('addonDetails');
                sessionStorage.removeItem('employeePlan');
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Payment Successfully Completed.', alignment: "centre" }
                });
                dispatch({
                    type: actionTypes.SUBSCRIPTION_ADD_VIEW,
                    payload: {}
                });
                dispatch({
                    type: actionTypes.REMOVE_SUBSCRIPTION_PLAN_RENEWAL,
                    payload: {}
                });
                dispatch({
                    type: actionTypes.SELECT_ADDON_PACKAGE,
                    payload: []
                });
                dispatch({
                    type: actionTypes.SUBSCRIPTION_SELECTED_PACKAGE,
                    payload: [],
                    planType: ''
                })
                dispatch({ type: actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN, payload: {} })
                dispatch({ type: actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN, payload: {} })
                data.history.push("/subscriptions");
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        }).catch(err => console.log('error', err))

    }

}
export const afterPaymentFailure = (data) => {
    return (dispatch) => {
        let userId = String(sessionStorage.getItem('userId'));
        let response = fetchData({ url: `/user/subscribeplan/failed?userId=${(userId)}&orderId=${String(data.orderId)}`, method: 'GET' });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Payment Failed.', alignment: "centre" }
                });
                sessionStorage.removeItem('subscriptionPay');
                sessionStorage.removeItem('addonDetails');
                sessionStorage.removeItem('employeePlan');
            }
        }).catch(err => console.log(err))
    }
}

export const renewPlanSubscription = (props) => {
    let plan = props && props.subscriptedPlan ? props.subscriptedPlan : "";
    let payment = props && props.paymentData ? props.paymentData : "";
    let formData = {
        planId: plan._id,
        userId: sessionStorage.getItem('userId'),
        email: payment.email,
        // amount: (plan.renewalCycle && plan.renewalCycle === "monthly") ? plan.monthlyAmount : (plan.renewalCycle && plan.renewalCycle === "yearly") ? plan.yearlyAmount : plan.licenseFee,
        amount: props.total,
        token: sessionStorage.getItem('token'),
        renewalCycle: plan.renewalType,
        planType: plan.planType,
        payment: JSON.stringify(payment),
        selectedPackage: props.selected_package
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/renewplan', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.BUTTON_SUCCESS });
                sessionStorage.removeItem('subscriptionPay');
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Subscription Renewaled Successfully.', alignment: "centre" }
                });
                props.history.push("/subscriptions");
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}
export const getSettingsData = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/get/settings', method: 'POST', data: {} });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SETTINGS_DATA,
                    payload: res.response
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const getjsonfolderslist = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let response = fetchData({ url: '/user/subscription/get_json_folder_list', method: 'POST', data: { 'userId': sessionStorage.getItem('userId') } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res && Number(res.status) === 1) {
                dispatch({
                    type: actionTypes.GET_FOLDERS_LIST,
                    payload: res.response && res.response.folders ? res.response : {}
                });
            }
        });
    }
}

export const getSubscriptionLists = (skip, limit, pageNumber, searchText, type, country = [], subs_category = [], subs_type = [], package_duration_filter = '', pricing_filter = '') => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SUBSCRIPTION_LISTS_ATTRIBUTES, 'page_no': pageNumber, 'skip': skip });
        dispatch({ type: actionTypes.SUBSCRIPTION_LISTS_SEARCHTEXT, 'searchText': searchText });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        let response = fetchData({ url: `/user/get-subscription-lists`, method: 'GET', params: { 'skip': skip, 'limit': limit, 'value': searchText, 'sendtype': type, package_duration: package_duration_filter, pricing: pricing_filter, countries: country, subscriptionTypes: subs_type, subscriptionCategory: subs_category } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.SEARCH_SUBSCRIPTION_LISTS, payload: res.response, count: res.total });
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                if (res.response && res.response.length > 0) {
                    dispatch({ type: actionTypes.SHOW_NO_DATA, payload: false })
                } else {
                    dispatch({ type: actionTypes.SHOW_NO_DATA, payload: true })
                }
            } else if (res.status === 2) {
                dispatch({ type: actionTypes.SEARCH_SUBSCRIPTION_LISTS, payload: [], count: 0 });
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                dispatch({ type: actionTypes.SHOW_NO_DATA, payload: true })
            } else {
                dispatch({ type: actionTypes.SEARCH_SUBSCRIPTION_LISTS, payload: [], count: 0 });
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                dispatch({ type: actionTypes.SHOW_NO_DATA, payload: true })
            }
        }).catch(err => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
        });
    }
    // let url = '/user/get-subscription-lists';
    // url = url + '&skip=' + skip + '&limit=' + limit;
    // if (searchText && searchText !== '' && searchText !== undefined) {
    //     url += '&value=' + searchText
    // }
    // if (type && type !== '' && type !== undefined) {
    //     url += '&sendtype=' + type
    // }
    // if (package_duration_filter && package_duration_filter !== undefined) {
    //     url += '&package_duration=' + package_duration_filter
    // }
    // if (pricing_filter && pricing_filter !== undefined) {
    //     url += '&pricing=' + pricing_filter
    // }
    // if (country && country !== undefined && country.length > 0) {
    //     url += '&country=' + String(country)
    // }
    // if (subs_type && subs_type !== undefined && subs_type.length > 0) {
    //     url += '&types=' + String(subs_type)
    // }
    // if (subs_category && subs_category !== undefined && subs_category.length > 0) {
    //     url += '&category=' + String(subs_category)
    // }
    // console.log('url', url)

    // let response = fetchData(
    //     url,
    //     'get'
    // );
}

export const get_admin_json_list = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        let response = fetchData({ url: '/admin/subscription/get_json_folder_list', method: 'POST', data: {} });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                dispatch({ type: actionTypes.SET_ADMIN_JSON, payload: res.response && res.response.folders ? res.response.folders : [] });
            } else {
                dispatch({ type: actionTypes.SET_ADMIN_JSON, payload: [] });
            }
        });
    }
}

export const setSearchValue = (searchText) => {
    return { type: actionTypes.SUBSCRIPTION_LISTS_SEARCHTEXT, 'searchText': searchText }
}

export const getSubscriptionDetails = (skip, limit, pageNumber, slug, search = '', history) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_DOCS_SEARCH_VAL, payload: search });
        dispatch({ type: actionTypes.SUBSCRIPTION_DETAILS_ATTRIBUTES, 'page_no': pageNumber, 'skip': skip });
        if (search && search.length > 0 && String(search) !== String(undefined) && String(search) !== String(null)) {
            dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_PENDING });
        } else {
            dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_PENDING });
            dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        }
        let response = fetchData({ url: '/user/get-subscrption-details', method: 'POST', data: { 'skip': skip, 'limit': limit, 'slug': slug, search } });
        response.then(res => {
            if (res.status === 1) {
                if (res.response && res.response.licensePeriod && res.response.licenseFee) {
                    dispatch({ type: actionTypes.SUBSCRIPTION_DETAILS, payload: res.response, documents: res.documents, count: res.total, files_count: res.files, folder_data: res.folders_data, file_path: res.file_path });
                    setTimeout(() => {
                        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                        dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_SUCCESS })
                    }, 3000);
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'No records found', alignment: "centre" }
                    });
                    return history.push('/404');
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'No records found', alignment: "centre" }
                });
                dispatch({ type: actionTypes.SUBSCRIPTION_DETAILS, payload: {}, documents: [], count: 0, files_count: 0, folder_data: [], file_path: [] });
                setTimeout(() => {
                    dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                    dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_SUCCESS })
                }, 3000);
                return history.push('/404');
            }
        }).catch(err => {
            setTimeout(() => {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_SUCCESS })
            }, 3000);
            return history.push('/404');
        });
    }
}

export const getSubscriptioSearchnDetails = (skip, limit, pageNumber, slug, search = '', history) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_DOCS_SEARCH_VAL, payload: search });
        dispatch({ type: actionTypes.SUBSCRIPTION_DETAILS_ATTRIBUTES, 'page_no': pageNumber, 'skip': skip });
        if (search && search.length > 0 && String(search) !== String(undefined) && String(search) !== String(null)) {
            dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_PENDING });
        } else {
            dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_PENDING });
        }
        let response = fetchData({ url: '/user/get-subscrption-details', method: 'POST', data: { 'skip': skip, 'limit': limit, 'slug': slug, search } });
        response.then(res => {
            if (res.status === 1) {
                if (res.response && res.response.licensePeriod && res.response.licenseFee) {
                    dispatch({ type: actionTypes.SUBSCRIPTION_DETAILS, payload: res.response, documents: res.documents, count: res.total, files_count: res.files, folder_data: res.folders_data, file_path: res.file_path });
                    setTimeout(() => {
                        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                        dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_SUCCESS })
                    }, 3000);
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'No records found', alignment: "centre" }
                    });
                    return history.push('/404');
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'No records found', alignment: "centre" }
                });
                dispatch({ type: actionTypes.SUBSCRIPTION_DETAILS, payload: {}, documents: [], count: 0, files_count: 0, folder_data: [], file_path: [] });
                setTimeout(() => {
                    dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                    dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_SUCCESS })
                }, 3000);
                return history.push('/404');
            }
        }).catch(err => {
            setTimeout(() => {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                dispatch({ type: actionTypes.SEARCH_PAGE_LOADER_SUCCESS })
            }, 3000);
            return history.push('/404');
        });
    }
}

export const downloadasIndex = (id, filename, count, update_date) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/subscrption-download', method: 'POST', data: { 'id': id, count, update_date } });
        // dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
        });
        response.then(res => {
            // dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                var oReq = new XMLHttpRequest();
                var URLToPDF = res.response;
                let url = window.location.origin + new URL(URLToPDF).pathname;
                oReq.open("GET", url, true);
                // oReq.open("GET", URLToPDF, true);
                oReq.responseType = "blob";
                oReq.onload = function () {
                    var file = new Blob([oReq.response], {
                        type: 'application/pdf'
                    });
                    saveAs(file, filename + ".pdf");
                };
                oReq.send();
                // window.open(res.response, '_blank');
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const descriptionModalView = (description) => {
    return {
        type: actionTypes.SUBSCRIPTION_DESCRIPTION_MODAL,
        payload: description ? description : ""
    }
}

export const USERS_SUBSCRIPTION_TAB = tab => {
    return {
        type: actionTypes.SET_TABS_FOR_SUBSCRIPTION,
        payload: tab ? tab : '1'
    }
}

export const SET_HEART_VALUE = id => {
    return { type: actionTypes.SET_HEART, payload: id ? id : '' }
}

export const SET_UNHEART_VALUE = id => {
    return { type: actionTypes.SET_HEART_UNCHECKC, payload: id ? id : '' }
}

export const SET_PRICING_FILTER = val => {
    return { type: actionTypes.SET_PRICINGFILTER, payload: val ? val : '' }
}

export const SET_PACKAGE_FILTER = val => {
    return { type: actionTypes.SET_PACKAGEFILTER, payload: val ? val : '' }
}

export const Clear_subs_Data = () => {
    return { type: actionTypes.CLEAR_SUBS_DATA, payload: [] }
}

export const Clear_docs_search = val => {
    return { type: actionTypes.SET_DOCS_SEARCH_VAL, payload: val ? val : '' }
}

export const DISMIS_1 = () => {
    return { type: actionTypes.DISMISS_SUBS, payload: true }
}

export const DISMIS = () => {
    return { type: actionTypes.DISMISS_COMP_DETAILS, payload: true }
}

export const changeView = (view = "list") => {
    return { type: actionTypes.SUBSCRIPTION_LIST_VIEW, payload: view }
}

export const subscriptionDetailView = (name, description, date) => {
    return {
        type: actionTypes.SUBSCRIPTION_DETAIL_MODAL,
        payload: {
            name: name,
            description: description,
            createdAt: date
        }
    }
}

export const show_bottom_modal = (msg) => {
    return {
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: true, text: msg, alignment: "centre" }
    }
}

export const payable_details = (details) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SUBSCRIPTION_PAYABLE_DETAILS,
            payload: details
        })
    }
}

export const initialPackageSelect = (packages, type = '', price) => {
    return {
        type: actionTypes.INITIAL_SUBSCRIPTION_SELECT,
        payload: packages && packages !== "" && packages !== undefined ? packages : null,
        planType: type,
    }
}

export const selectedPackages = (packages, type = '') => {
    return {
        type: actionTypes.SUBSCRIPTION_SELECTED_PACKAGE,
        payload: packages && packages !== "" && packages !== undefined ? packages : null,
        planType: type,
    }
}
export const removeSelectedPackages = () => {
    return {
        type: actionTypes.REMOVE_SUBSCRIPTION_SELECTED_PACKAGE,
    }
}
export const userreadMore = () => {
    return {
        type: actionTypes.READ_MORE_USERCONTENT,
    }
}
export const allreadMore = () => {
    return {
        type: actionTypes.READ_MORE_ALLCONTENT,
    }
}
export const clearSelectedPackages = (packages) => {
    return {
        type: actionTypes.CLEAR_SELECTED_SUB_PLANS,
        payload: packages
    }
}
export const selectedPackagesDetails = (details) => {
    return {
        type: actionTypes.SUBSCRIPTION_SELECTED_PACKAGE_DETAILS,
        payload: details
    }
}
export const licenseRenewal = (key, type) => {
    return {
        type: actionTypes.SUBSCRIPTION_PLAN_RENEWAL,
        payload: { package: key, plantype: type }
    }
}
export const removeLicenseRenewal = () => {
    return {
        type: actionTypes.REMOVE_SUBSCRIPTION_PLAN_RENEWAL,
    }
}
export const getDocumentManagement = (props) => {
    let payment = props && props.paymentData ? props.paymentData : "";
    let formData = {
        userId: sessionStorage.getItem('userId'),
        email: payment.email,
        amount: props.total,
        token: sessionStorage.getItem('token'),
        payment: JSON.stringify(payment)
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/get/document/management', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Document Management Renewed Successfully.', alignment: "centre" }
                });
                dispatch({
                    type: actionTypes.SUBSCRIPTION_ADD_VIEW,
                    payload: {}
                });
                props.history.push("/subscriptions");
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }

}
export const getSelectedPackage = (data, plantype) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SELECT_ADDON_PACKAGE,
            payload: data ? data : [],
            method: plantype ? plantype : ''
        });
    }
}
export const addDocument = (item) => {
    return {
        type: actionTypes.ADD_NEW_SUBSCRIPTION,
        payload: item,
    }
}
export const removeDocument = (id) => {
    return {
        type: actionTypes.REMOVE_SUBSCRIPTION,
        payload: id,
    }
}
export const clearDocument = () => {
    return {
        type: actionTypes.CLEAR_ADDED_SUBSCRIPTION,
    }
}

export const planSelection = (packages, planType, user) => {
    return {
        type: actionTypes.PACKAGE_SELECT_CHECKBOX,
        payload: packages && packages.length > 0 ? packages : [],
        plan: planType ? planType : {},
        user: user
    }
}

export const countryList = (value) => {
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/country/list', method: 'POST', data: { searchText: value } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.COUNTRY_LIST,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.COUNTRY_LIST,
                    payload: [],
                })
            }
        }).catch(err => console.log(err))
    }
}

export const subscriptionTypeList = (value) => {
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/type/list', method: 'POST', data: { searchText: value } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_TYPE_LIST,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_TYPE_LIST,
                    payload: []
                })
            }
        }).catch(err => console.log(err))
    }
}

export const categoryList = (value) => {
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/category/list', method: 'POST', data: { searchText: value } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_CATEGORY_LIST,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_CATEGORY_LIST,
                    payload: []
                })
            }
        }).catch(err => console.log(err))
    }
}

export const getSubscriptionPriceDetails = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/admin/subscription/price/details', method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_PRICE_DETAILS,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.SUBSCRIPTION_PRICE_DETAILS,
                    payload: {}
                })
            }
        }).catch(err => console.log(err))
    }
}

export const selectPrintPackage = (name) => {
    return {
        type: actionTypes.SELECT_PRINT_PACK,
        payload: name
    }
}

export const insertSelectedPrice = (id) => {
    return {
        type: actionTypes.INSERT_SELECTED_PRICES,
        payload: id
    }
}
export const getUserSubscriptions = (id) => {
    return (dispatch) => {
        let response = fetchData({ url: `/user/subscription/details?userId=${id}`, method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.GET_USER_SUBSCRIPTIONS,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.GET_USER_SUBSCRIPTIONS,
                    payload: {}
                })
            }
        }).catch(err => console.log(err))
    }
}
export const getUserInvoiceList = (userId, filters, activePage) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.INVOICE_PAGINATION, payload: +activePage })
        let response = fetchData({ url: `/user/invoice/list`, method: 'POST', data: { userId, ...filters } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.GET_USER_INVOICE_LIST,
                    payload: res.response,
                    total: res.total
                })
            } else {
                dispatch({
                    type: actionTypes.GET_USER_INVOICE_LIST,
                    payload: []
                })
            }
        }).catch(err => console.log(err))
    }
}

export const getInvoiceDetails = (id) => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: `/user/invoice/details?invoiceId=${id}&userId=${userId}`, method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.GET_USER_INVOICE_DETAILS,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.GET_USER_INVOICE_DETAILS,
                    payload: {}
                })
            }
        }).catch(err => console.log(err))
    }
}
export const cancelUserSubscription = (id) => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let subs_id = id ? id : '';
        let response = fetchData({ url: `/user/subscription/cancel?subs_id=${subs_id}&userId=${userId}`, method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
                dispatch({ type: actionTypes.SHOW_SUBSCRIPTION_CANCEL_MODAL });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        }).catch(err => console.log(err))
    }
}

export const updateSubscriptions = (val = false) => {
    return {
        type: actionTypes.CAN_UPDATE_SUBSCRIPTION,
        payload: val
    }
}

export const BottomAlert = (value) => {
    return {
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: true, text: value, alignment: "centre" }
    }
}

export const employeePlan = (obj) => {
    return {
        type: actionTypes.EMPLOYEE_SELECT_PLAN,
        payload: obj
    }
}

export const getFileDetails = (data, history,subscriptionDetails) => {
    return async (dispatch) => {
        const res = await fetchData({ url: '/site/fileDetails', method: 'GET', params: { data: String(data) } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.FILE_DETAILS_PAGE, payload: res.response.content })
            history && history.push({ pathname: `/view/Document/${res.response.slug}`, state: { data } ,from: history.location.pathname,details:subscriptionDetails,fileName:res.response.fileName,fileData:res.response.fileData})
        } else if (res.status === 2) {
            history.push('/policies-procedures')
        } else {
            history.push('/policies-procedures')
        }
    }
}
export const getDocPlanDetails = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN, payload: data })
}
export const getEmployeePlanDetails = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN, payload: data })
}
export const changePeriod = (tab) => async (dispatch) => {
    dispatch({ type: actionTypes.SUBSCRIPTION_PLAN_PERIOD, payload: tab })
    dispatch({ type: actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN, payload: {} })
    dispatch({ type: actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN, payload: {} })
}
export const updateSubscriptionPlan = (data, plantype, props) => async (dispatch) => {
    const res = await fetchData({ url: '/user/subscription/plan/update', method: 'GET', params: { plan: (data), type: plantype, userId: sessionStorage.getItem('userId') } })
    if (res.status === 1) {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: res.response, alignment: "centre" }
        });
        sessionStorage.removeItem('addonDetails');
        sessionStorage.removeItem('employeePlan');
        dispatch({ type: actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN, payload: {} });
        dispatch({ type: actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN, payload: {} });
        // dispatch({ type: actionTypes.SET_TABS_FOR_SUBSCRIPTION, payload: '1' });
        dispatch({ type: actionTypes.SHOW_PLAN_UPDATE_MODAL, payload: '' });
    } else {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: res.response, alignment: "centre" }
        });
    }
}

export const updatePlanModalToggle = (key) => {
    return {
        type: actionTypes.SHOW_PLAN_UPDATE_MODAL,
        payload: key ? key : ''
    }
}
export const cancelSubscritionToggle = () => {
    return {
        type: actionTypes.SHOW_SUBSCRIPTION_CANCEL_MODAL
    }
}
export const clearSelectdPlans = () => (dispatch) => {
    dispatch({ type: actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN, payload: {} });
    dispatch({ type: actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN, payload: {} });
    sessionStorage.removeItem('addonDetails');
    sessionStorage.removeItem('employeePlan');
    sessionStorage.removeItem('subscriptionPay');
    localStorage.removeItem('offline_cart_employeePlan');
}

export const removeEmployeePlan = () => async (dispatch) => {
    dispatch({ type: actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN, payload: {} });
    sessionStorage.removeItem('employeePlan');
    localStorage.removeItem('offline_cart_employeePlan')
}
export const clearSelectedPlans = () => async (dispatch) => {
    dispatch({ type: actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN, payload: {} });
    sessionStorage.removeItem('addonDetails');
}

export const getStorage = () => {
    return async (dispatch) => {
        const userId = sessionStorage.userId ? sessionStorage.userId : '';
        const res = await fetchData({ url: '/user/strorage/usage', method: 'GET', params: { userId } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.STORAGE_DETAILS, payload: res.response });
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: res.response, alignment: "centre" }
            });
        }
    }
};

export const selectSubscription = (plan, type) => {
    return {
        type: actionTypes.DOCUMENT_SUBSCRIPTION_SELECTION,
        payload: plan ? plan : '',
        planType: type,
    }
}
export const selectLicenceType = (key) => {
    return {
        type: actionTypes.DOCUMENT_LICENCE_TYPE,
        payload: key,
    }
}

export const afterSuccess = (data) => async (dispatch) => {
    let formData = {};
    formData['userId'] = String(sessionStorage.getItem('userId'));
    formData['paymentDetails'] = JSON.stringify(data.paymentData);
    formData['selectedPackages'] = data.selectedPackages;
    formData['plantype'] = data.plantype;
    formData['subscription_id'] = data.subscription_id;
    formData['employeePlan'] = '';
    formData['orderId'] = data.orderId;
    formData['employeePlan'] = data.employeePlan;
    let request = await fetchData({ url: `/user/payment/update/success?data=${JSON.stringify(formData)}`, method: 'GET' });
    if (request && request.status === 1) {
        dispatch({ type: actionTypes.BUTTON_SUCCESS });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Payment Successfully Completed.', alignment: "centre" }
        });
        data.history.push("/subscriptions");
    } else {
        dispatch({ type: actionTypes.BUTTON_SUCCESS });
    }
}