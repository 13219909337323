import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import routes from '../../routes';
import HeaderComponent from '../Common/Header';
import FooterComponent from '../Common/Footer';
import SidebarComponent from '../Common/Sidebar';
import HomeComponent from '../Home';
import { getSettingsData } from '../../actions/subscriptionAction';
import { Helmet } from 'react-helmet-async';
//css
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import './index.css';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();
    return (
        <Route {...rest} render={(props) => (sessionStorage.getItem('isAuthenticated') === "true" || (location.search && location.search !== '')) ?
            (<Component {...props} />) :
            (<Redirect to='/' />)} />
    )
}

export const Spinner = () => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    let employee = urlParams.get("employeePlan");
    let selected_addon = urlParams.get("selected_addon");
    if (employee) {
        try {
            let decodedEmployeePlan = decodeURIComponent(window.atob(employee));
            let employeePlan = JSON.parse(decodedEmployeePlan);
            sessionStorage.setItem('employeePlan', JSON.stringify(employeePlan));
            sessionStorage.setItem('instantUpdatePackage', true)
        } catch (error) {
            console.error('Error decoding or parsing employeePlan:', error);
        }
    }
    if (selected_addon) {
        try {
            let decodedselected_addon = decodeURIComponent(window.atob(selected_addon));
            let addon = JSON.parse(decodedselected_addon);
            sessionStorage.setItem('selected_addon', JSON.stringify(addon));
            sessionStorage.setItem('instantUpdatePackage', true)
        } catch (error) {
            console.error('Error decoding or parsing selected_addon:', error);
        }
    }
    return (
        <div>
            {(location.pathname === '/company/dashboard'
                || location.pathname === '/company/documents'
                || location.pathname === '/company/users'
                || location.pathname === '/company/users/add'
                || location.pathname === '/company/users/edit'
                || location.pathname === '/company/users/activity'
                || location.pathname === '/company/saved-documents'
                || location.pathname === '/company/create-new-documents'
                || location.pathname === '/company/subscriptions'
                || location.pathname === '/company/index'
                || location.pathname === '/company/documents/editor'
                || location.pathname === 'company/subscription/pay'
                || location.pathname === '/company/documents/compare'
                || location.pathname === '/company/invoice'
                || location.pathname === '/company/employees'
                || location.pathname === '/company/deleted/employees'
                || location.pathname === '/company/employee/add'
                || location.pathname === '/company/employee/edit'
                || location.pathname === '/company/myfiles'
                || location.pathname === '/company/history'
                || location.pathname === '/company/policies'
                || location.pathname === '/company/groups'
                || location.pathname === '/company/groups'                

            ) &&
                <div className="left-side">
                    <div className="left-dev">
                        <ul>
                            <li className="long-logo">
                                <div className="menu-load"></div>
                            </li>
                            <li>
                                <div className="small-img-ldr"></div>
                                <div className="menu-load"></div>
                            </li>
                            <li>
                                <div className="small-img-ldr"></div>
                                <div className="menu-load"></div>
                            </li>
                            <li>
                                <div className="small-img-ldr"></div>
                                <div className="menu-load"></div>
                            </li>
                            <li>
                                <div className="small-img-ldr"></div>
                                <div className="menu-load"></div>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            {location.pathname === '/company/dashboard' &&
                <div className="right-side">
                    <div className="top-box-ani">
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>

                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>

                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-box-ani">
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-box">
                            <div className="dashboard_rights">
                                <div className="left_dash">
                                    <p className="number-load">2345</p>
                                    <p className="number-load">2345</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(location.pathname === '/company/documents'
                || location.pathname === '/company/users/add'
                || location.pathname === '/company/users/edit'
                || location.pathname === '/company/users'
                || location.pathname === '/company/index'
                || location.pathname === '/company/subscriptions'
                || location.pathname === '/company/employees'
                || location.pathname === '/company/deleted/employees'
                || location.pathname === '/company/employee/add'
                || location.pathname === '/company/employee/edit'
                || location.pathname === '/company/myfiles'
                || location.pathname === '/company/history'
                || location.pathname === '/company/policies'
                || location.pathname === '/company/groups'
                || location.pathname === '/company/publishedpolicies'
            ) &&
                <div className="right-side-table">
                    <div>
                        <div className="head-left-ani"></div>
                        <div className="head-right-ani"></div>
                        <div className="head-right-ani"></div>
                    </div>
                    <div>
                        <table className="tab_ani">
                            <tbody>
                                <tr>
                                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                                </tr>
                                <tr>
                                    <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                </tr>
                                <tr>
                                    <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                </tr>
                                <tr>
                                    <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
            {(location.pathname === '/company/policy/documents'
                || location.pathname === '/company/policy/document/view')
                && <div className='pos-center'>
                    <div className="full-page-loader"></div>
                </div>}
        </div>
    );
}

const App = (props) => {
    const dispatch = useDispatch(),
        logOutData = useSelector(state => state.UIReducer.logOutData),
        settingsData = useSelector(state => state.subscriptionData.settingData);
    // showSignup = useSelector(state => state.userLoginData.showSignup),
    // isAuthenticated = useSelector(state => state.authentication.isAuthenticated),
    // showLogOutModal = useSelector(state => state.UIReducer.showLogOutModal),    

    useEffect(() => {
        if (window.location.search && window.location.search.includes('?gclid=') && !window.location.search.includes('?user=')) {
            return window.location = '/';
        } else if (window.location.search && window.location.search.includes('?') && !window.location.search.includes('?user=')) {
            return window.location = '/';
        }
        if (window.location.pathname === '/employee') {
            return window.location = '/';
        }
        dispatch(getSettingsData());
        // document.title = (settingsData && settingsData.site_title) ? settingsData.site_title : "";
        let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
        if (!link) {
            link = document.createElement('link');
            link.id = 'favicon';
            link.rel = 'shortcut icon';
            document.head.appendChild(link);
        }
        link.href = (settingsData && settingsData.favicon) ? settingsData.favicon : process.env.PUBLIC_URL + "/assets/images/favicon-d.PNG";
    }, [])

    useEffect(() => {
        // document.title = (settingsData && settingsData.site_title) ? settingsData.site_title : "";
        let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
        if (!link) {
            link = document.createElement('link');
            link.id = 'favicon';
            link.rel = 'shortcut icon';
            document.head.appendChild(link);
        }
        link.href = (settingsData && settingsData.favicon) ? settingsData.favicon : process.env.PUBLIC_URL + "/assets/images/favicon-d.PNG";
    }, [settingsData])

    return (
        <Router basename="/company">
            <Helmet>
                <title>Cloud Document Management System UK | ClouDoc</title>
                <meta name="keyword" content='Cloud Document Management System UK' />
                <meta name="description" content='A Cloud document management system gives your business access to all file types, allowing you to be more efficient, productive & organized. Get started now' />
            </Helmet>
            <Suspense fallback={<Spinner />}>
                <Route key={"header"} render={props => <HeaderComponent {...props} />} />
                <Route key={"sidebar"} render={props => <SidebarComponent {...props} />} />
                <Switch>
                    {routes.map((route, i) =>
                        (route && route.private === false) ?
                            <Route key={i} path={route.path} exact={route.exact} render={props => <route.component {...props} />} /> :
                            <PrivateRoute key={i} path={route.path} exact={route.exact} component={route.component} />
                    )}
                </Switch>
                <ToastContainer autoClose={2000} />
                {/* {logOutData === true ? <Route key={"home"} exact={true} render={props => <HomeComponent {...props} />} /> : null} */}
                <Route key={"footer"} render={props => <FooterComponent {...props} app={this} />} />
            </Suspense>
        </Router>
    );
}

export default App;
