import React from 'react';
import { openPopupWidget } from "react-calendly";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal, ModalBody } from 'reactstrap';
import { changeModal, getNotifications } from "../../../actions/notificationActions";
import { logOut, LogOutModalView, mobileModalFunc } from "../../../actions/uiAction";
import { authModal } from "../../../actions/userLoginAction";
import trolly from "../../../images/new-design/cart-new.svg";
import './index.css';


const url = "https://calendly.com/d/dpp-jhs-cxx/cloudoc-system-demo";
const prefill = {}
const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '00a2ff',
    textColor: '4d5055'
}
const utm = {
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: ''
}
const MobileModal = (props) => {
    const mobileModal = useSelector(state => state.UIReducer.mobileModal),
        settingsData = useSelector(state => state.subscriptionData.settingData),
        cartCount = useSelector(state => state.UIReducer.cartCount),
        offline_cart_data_count = useSelector(state => state.cartData.offline_cart_data_count),
        unReadCount = useSelector(state => state.notificationData.unread),
        authCheck = useSelector(state => state.authentication.isAuthenticated),
        dispatch = useDispatch();

    const setActiveClass = (data) => sessionStorage.setItem("activeClass", data),
        myProfilefun = (type) => {
            props.history.push("/profile");
            sessionStorage.setItem("profileType", type);
            dispatch(LogOutModalView(props));
        },
        logout_user = (e) => {
            e.preventDefault();
            dispatch(logOut(props.history));
        },
        mainToggle = () => {
            dispatch(authModal('home'))
            dispatch(mobileModalFunc())
        },
        notification = (key) => {
            let getUserId = sessionStorage.getItem("userId");
            dispatch(getNotifications(getUserId, 10));
            key === "home" ? dispatch(changeModal("home")) : dispatch(changeModal("document"));
        },
        handleItem = (value) => {
            setActiveClass(value)
            dispatch(mobileModalFunc())
        },
        handleLoginOption = (e, key) => {
            if (key === 'myaccount') {
                myProfilefun("myprofile")
            } else if (key === 'dashboard') {
                props.history.push("/dashboard")
            } else if (key === 'orderhistory') {
                props.history.push("/order/history")
            } else if (key === 'Logout') {
                logout_user(e)
            }
            dispatch(mobileModalFunc())
        },
        openPopup = () => {
            dispatch(mobileModalFunc())
            openPopupWidget({ url, prefill, pageSettings, utm })
        };

    let isAuthenticated = sessionStorage.getItem("isAuthenticated") ? sessionStorage.getItem("isAuthenticated") : authCheck;
    let activeClass = sessionStorage.getItem("activeClass") ? sessionStorage.getItem("activeClass") : null;

    return <Modal isOpen={mobileModal} toggle={(e) => dispatch(mobileModalFunc())} className="res_menu">
        <ModalBody>
            <ul className="navbar-nav">
                {String(isAuthenticated) === String("true") ? (
                    <li className="nav-item">
                        <Link className="nav-link" to="/documents">Document Library</Link>
                    </li>
                ) : null}
                {String(isAuthenticated) === String("true")
                    ? <li className="nav-item">
                        <Link className="nav-link" onClick={() => handleItem()} to="/policies-procedures">Policies & Procedures </Link>
                    </li>
                    : <li className="nav-item" onClick={() => handleItem(1)}>
                        <Link className={activeClass === "1" ? "nav-link active-navItem" : "nav-link"} to="/policies-procedures">Policies & Procedures </Link>
                    </li>}
                {String(isAuthenticated) === String("true")
                    ? null : <li className="nav-item" onClick={() => handleItem(2)}>
                        <Link className={activeClass === "2" ? "nav-link active-navItem" : "nav-link"} to="/features">System Features </Link>
                    </li>}
                    {String(isAuthenticated) === String("true")
                    ? null : <li className="nav-item">
                        <a className="nav-link" target="_self" href={settingsData.blog_url}>Blog</a>
                    </li>}
                {String(isAuthenticated) === String("true")
                    ? null : <li className="nav-item" onClick={() => handleItem(3)}>
                        <Link className={activeClass === "3" ? "nav-link active-navItem" : "nav-link"} to="/contact-us">Contact Us </Link>
                    </li>}
                {String(isAuthenticated) === String("true")
                    ? null : <li className="nav-item" onClick={() => handleItem(4)}>
                        <Link className={activeClass === "4" ? "nav-link active-navItem" : "nav-link"} to="/knowledge">Help Centre</Link>
                    </li>}
                {String(isAuthenticated) === String("true")
                    ? null : <li className="nav-item" onClick={() => handleItem(5)}>
                        <Link className={activeClass === "5" ? "nav-link active-navItem" : "nav-link"} to="/faq">FAQs</Link>
                    </li>}

                <div className="same_row">
                    <li className="nav-item get-a-demo" onClick={() => setActiveClass(10)}>
                        <span className={activeClass === "10" ? "nav-link active-navItem" : "nav-link"} onClick={openPopup} id={String(isAuthenticated) === String(true) ? "buy-pol-authenticated" : "buy-pol"}>Get a Demo </span>
                    </li>
                    {String(isAuthenticated) === String(true) ? (
                        <li className="nav-item dropdown site-dropdown">
                            <a className="nav-link dropdown-toggle my-acc-lnk" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">My Account</a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" onClick={(e) => handleLoginOption(e, 'myaccount')} >My account</a>
                                <a className="dropdown-item" onClick={(e) => handleLoginOption(e, 'dashboard')} >Dashboard</a>
                                <a className="dropdown-item" onClick={(e) => handleLoginOption(e, 'orderhistory')} >Order history</a>
                                <a className="dropdown-item log-out" onClick={(e) => handleLoginOption(e, 'Logout')}>Logout</a>
                            </div>
                        </li>
                    ) : null}

                    {String(isAuthenticated) !== String(true) && (
                        <li className="nav-item demo-but">
                            <Link className="nav-link" onClick={mainToggle}>Sign In</Link>
                        </li>
                    )}
                    {/* <li>
                    <div className="mobile-num"> <span><FontAwesomeIcon icon={faPhoneAlt} /></span><span className="get-num"><p>Get a question? Call Us</p><a href="tel:0330 808 0050">{settingsData && settingsData.phone && settingsData.phone !== '' && settingsData.phone !== undefined ? (settingsData.phone).slice(0, 4) + ' ' + (settingsData.phone).slice(4, 7) + ' ' + (settingsData.phone).slice(7) : '0330 808 0050'}</a></span></div>
                </li> */}
                    {String(isAuthenticated) === String(true)
                        ?
                        <li className="nav-item nav-basket" >
                            {/* <Link className="nav-link" to="/cart">
                                <div className="basket-icon"><img src={trolly} alt='cart' /></div>
                                <p className={cartCount > 0 ? "bell" : ""}>{cartCount > 0 ? cartCount : ""}</p>
                            </Link> */}
                        </li>
                        :
                        <li className="nav-item  basket-svg" onClick={() => setActiveClass(5)}>
                            <Link className={activeClass === "5" ? "nav-link active-navItem" : "nav-link"} to="/cart">
                                {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
                                <div className={activeClass === "5" ? "basket-icon active-basket" : "basket-icon"}><img src={trolly} alt='cart' /></div>
                                <p className={offline_cart_data_count > 0 ? "bell" : ""}>
                                    {offline_cart_data_count > 0 ? offline_cart_data_count : ""}</p>
                            </Link>
                        </li>
                    }
                    {/* {String(isAuthenticated) === String(true) ?
                        <li className="nav-item bell-icon">
                            <IconButton aria-label="notification" onClick={() => notification("home")}>
                                {unReadCount > 0 ? <Badge badgeContent={unReadCount} className="home-badge">
                                </Badge> : null}
                                <NotificationsIcon style={{ color: grey[900] }} />
                            </IconButton>
                        </li> : null
                    } */}
                </div>
            </ul>
        </ModalBody >
    </Modal >
};

export default MobileModal;
