import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import './index.css'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { addedToCart, getPageLists, OFFLINE_CART } from '../../actions/cartAction';
import { featuresToggle, getLatestUpdates, getSampleDocument, getTestimonialLists, getUpdatedDocuments, getUserBlogs, reqularityToggle } from '../../actions/homeAction';
import { getSettingsData, getSubscriptionLists } from '../../actions/subscriptionAction';
import { documentModalView, toggleOfferModal, getSlug } from '../../actions/uiAction';
import { open_iframe_modal } from "../../actions/documentAction";
import moment from 'moment';
import Moment from 'react-moment';
import WOW from '../../js/wow';
import 'react-owl-carousel2/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-accessible-accordion/dist/fancy-example.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import price_1 from '../../images/new-design/doc.png';
import refresh_1 from '../../images/new-design/refresh.png';
import feature1 from "../../images/new-design/feature1.png";
import feature2 from "../../images/new-design/feature2.png";
import feature3 from "../../images/new-design/feature3.png";
import feature4 from "../../images/new-design/feature4.png";
import feature5 from "../../images/new-design/feature5.png";
import mobilefeature from "../../images/new-design/mobile.png";
import mobilefeature2 from "../../images/new-design/mobile2.png";
import mobilefeature6 from "../../images/new-design/mobile6.png";
import policy_1 from "../../images/new-design/policy-1.png";
import policy_2 from "../../images/new-design/policy-2.png";
import policy_3 from "../../images/new-design/policy-3.png";
import policy_4 from "../../images/new-design/policy-4.png";
import record_1 from "../../images/new-design/record-1.png";
import record_2 from "../../images/new-design/record-2.png";
import record_3 from "../../images/new-design/record-3.png";
import record_4 from "../../images/new-design/record-4.png";
import client_1 from "../../images/new-design/client-1.png";
import client_2 from "../../images/new-design/client-2.png";
import client_3 from "../../images/new-design/client-3.png";
import client_4 from "../../images/new-design/client-4.png";
import client_7 from "../../images/new-design/client-7.png";
import client_8 from "../../images/new-design/client-8.png";
import update_bac from "../../images/new-design/update-bac.svg";
import leftimg from "../../images/new-design/home-page/left-img.png";
import rightimg from "../../images/new-design/home-page/right-img.png";
import careleft from "../../images/new-design/home-page/care-left.png";
import c1 from "../../images/new-design/home-page/c-1.png";
import c2 from "../../images/new-design/home-page/c-2.png";
import c3 from "../../images/new-design/home-page/c-3.png";
import c4 from "../../images/new-design/home-page/c-4.png";
import tabicon from "../../images/new-design/home-page/user-manage.png";
import fixedphone from "../../images/new-design/home-page/fixed-phone.svg";
import hand from '../../images/new-design/hand.png';
import bussiness from '../../images/new-design/bussiness.png';
import { Helmet } from 'react-helmet-async';
import { policyReadNotifyModal } from '../../actions/employeePolicyAction';
import PolicyUnreadNotify from '../Common/PolicyUnreadNotifyModal';
import price_1_h from '../../images/new-design/small-icons/docs-icon.png';
import refresh_1_h from '../../images/new-design/small-icons/refresh-icon.png';
// import classnames from 'classnames';
// import dms_1 from '../../images/new-design/need-1.svg';
// import dms_2 from '../../images/new-design/need-2.svg';
// import dms_3 from '../../images/new-design/need-3.svg';
// import dms_4 from '../../images/new-design/need-4.svg';
// import why_choose_left from '../../images/new-design/img-cd.png';
// import feature1 from "../../images/new-design/fet-1.png";
// import userManage from '../../images/new-design/slider_1.png';
// import tab_1 from "../../images/new-design/small-icons/tab-banner.png";
// import doc_img_1 from "../../images/new-design/small-icons/doc-update.png";
// import sys_icon_2 from "../../images/new-design/small-icons/doc-index.png";
// import sys_feature_right from "../../images/new-design/small-icons/sys-feature-right-img.png";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { subdomainRemindModalTogggle } from '../../actions/myProfileAction';
import ScrollTop from '../Common/ScroolTop';
import OfferModal from './OfferModal';
import IframeModal from "../Common/IframeModal";
import { openPopupWidget } from "react-calendly";
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <h1>{children}</h1>}
        </div>
    );

}

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        //   flexGrow: 1,
        //   backgroundColor: theme.palette.background.paper,
        display: "flex",
        //   height: 224
    },
    tabs: {
        borderRight: `0px solid ${theme.palette.divider}`
    }
}));

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const Home = (props) => {
    const dispatch = useDispatch(),
        settingsData = useSelector(state => state.subscriptionData.settingData),
        userBlogLists = useSelector(state => state.homePageData.userBlogLists),
        subscriptionList = useSelector(state => state.subscriptionData.subscriptionLists),
        selected_package = useSelector(state => state.subscriptionData.selectedPackage),
        activeTab = useSelector(state => state.homePageData.activeTab),
        activeTab2 = useSelector(state => state.homePageData.activeTab2),
        documentList = useSelector(state => state.homePageData.documentList),
        showDocumentModal = useSelector(state => state.UIReducer.showDocumentModal),
        showDocument = useSelector(state => state.UIReducer.showDocument),
        userDetails = useSelector(state => state.myProfile.addViewData),
        showUnreadPolicyNotify = useSelector(state => state.employeePolicyData.showUnreadPolicyNotify),
        offerShowModal = useSelector(state => state.UIReducer.offerShowModal),
        // companyDetails = useSelector(state => state.userLoginData.companyDetails),
        // authCheck = useSelector(state => state.authentication.isAuthenticated),
        offerSlugs = useSelector(state => state.UIReducer.offerSlugs),
        viewIframeModal = useSelector(state => state.documentData.viewIframeModal);

    // testimonialLists = useSelector(state => state.homePageData.testimonialLists),
    // newUpdateDocuments = useSelector(state => state.homePageData.newUpdateDocuments),
    // featuredDocuments = useSelector(state => state.homePageData.featuredDocuments),
    // pop_show_hide = useSelector(state => state.UIReducer.pop_show_hide),        
    // subscriptionDetails = useSelector(state => state.subscriptionData.subscriptionDetails),
    // sampleDocList = useSelector(state => state.homePageData.sampleDocList),
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [imgVal, setImage] = React.useState(1);

    const offerOn = settingsData && settingsData.offerStatus && settingsData.offerStatus === 1 ? true : false;
    const isPackageOffer = offerOn && settingsData.packagediscountStatus && settingsData.packagediscountStatus === 1 ? true : false;
    const isTrailOffer = offerOn && settingsData.trialStatus && settingsData.trialStatus === 1 ? true : false;

    const packageDiscount = isPackageOffer && settingsData.packageDiscountPercent ? settingsData.packageDiscountPercent : 0;
    const trailDiscount = isTrailOffer && settingsData.trialPeriod ? settingsData.trialPeriod : '';

    const packageValidDate = isPackageOffer && settingsData.offerExpiry ? settingsData.offerExpiry : '';
    const trailValidDate = isTrailOffer && settingsData.trialExpiry ? settingsData.trialExpiry : '';

    // let isAuthenticated = sessionStorage.getItem("isAuthenticated") ? sessionStorage.getItem("isAuthenticated") : authCheck;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const changeOfferModal = (val) => {

        // setTimeout(() => {
        dispatch((toggleOfferModal(val)))
        // }, 3000);
    }
    const getSlugArr = (arr) => {
        dispatch(getSlug(arr))
    }
    useEffect(() => {
        new WOW().init();
        window.scrollTo(0, 0);
        dispatch(getSubscriptionLists(0, 4, 1, '', 'default'));
        dispatch(getTestimonialLists(0, 10));
        dispatch(getUpdatedDocuments(0, 4));
        dispatch(getSettingsData());
        dispatch(getUserBlogs());
        dispatch(getPageLists());
        dispatch(featuresToggle("1"));
        dispatch(getLatestUpdates(0, 5));
        dispatch(getSampleDocument());
    }, []);

    useEffect(() => {
        let x = getCookie('offerTime');
        if (offerOn) {
            if (x && (x === true || x === 'true')) {
                changeOfferModal(false)
            } else {
                const expires = (new Date(Date.now() + 86400 * 1000)).toUTCString();
                document.cookie = `offerTime = true; expires=${expires};`
                changeOfferModal(true)
            }
        }
    }, [offerOn])
    useEffect(() => {
        if (userDetails && userDetails.role && userDetails.role === 'employee' && userDetails.assignedpolicyDocuments && userDetails.assignedpolicyDocuments && userDetails.assignedpolicyDocuments.length > 0) {
            let unreadPolicy = [], allAssignPolicy = [...userDetails.assignedpolicyDocuments];
            unreadPolicy = allAssignPolicy.filter(e => +(e.status) === 0);
            if (unreadPolicy && unreadPolicy.length > 0) {
                dispatch(policyReadNotifyModal(true))
            }
        }
        if (userDetails && userDetails.role && userDetails.role === 'company') {
            if (userDetails && userDetails.companyDetails && userDetails.companyDetails.subdomain && userDetails.companyDetails.subdomain !== undefined && userDetails.companyDetails.subdomain !== '' && userDetails.companyDetails.subdomain !== null) {
                dispatch(subdomainRemindModalTogggle(false));
            } else if ((userDetails && userDetails.companyDetails && userDetails.companyDetails.subdomain !== undefined && String(userDetails.companyDetails.subdomain).length === 0) || (userDetails && userDetails.companyDetails && userDetails.companyDetails.subdomain === undefined)) {
                dispatch(subdomainRemindModalTogggle(true));
            }
        }
    }, [userDetails])
    useEffect(() => {
        if (settingsData && settingsData.offerPackageIDS && settingsData.offerPackageIDS.length > 0) {
            getSlugArr(settingsData.offerPackageIDS)
        }
    }, [settingsData.offerPackageIDS]);

    // useEffect(() => {
    //     if (companyDetails && Object.keys(companyDetails).length > 0 && String(isAuthenticated) === 'false') {
    //         props.history.push('/login')
    //     }
    // }, [companyDetails]);

    const toggle = tab => {
        if (activeTab !== tab) dispatch(featuresToggle(tab));
    },
        toggle2 = tab2 => {
            if (activeTab2 !== tab2) dispatch(reqularityToggle(tab2));
        },
        addToCart = (e, id, method, item) => {
            e.preventDefault();
            let printingFee = 0, renewalType = '';
            if (
                sessionStorage.getItem("userId") &&
                sessionStorage.getItem("userId") !== ""
            ) {
                dispatch(addedToCart(id, sessionStorage.getItem("userId"), props, "subscription", method, selected_package && selected_package.length > 0 ? selected_package : [], '', ''));
            } else {
                dispatch(OFFLINE_CART(item, selected_package && selected_package.length > 0 ? selected_package : [], printingFee, renewalType, settingsData));
            }
        },
        setActiveClass = (data) => {
            sessionStorage.setItem("activeClass", data);
        },
        createMarkup = (description) => {
            return { __html: description || "" };
        },
        gotopage = (key) => {
            if (key === 'cqc') {
                props.history.push({ pathname: '/policies-procedures', state: { country: 'england' } })
                toggle2('1');
            } else if (key === 'ciw') {
                props.history.push({ pathname: '/policies-procedures', state: { country: 'wales' } })
                toggle2('2');
            } else if (key === 'cis') {
                props.history.push({ pathname: '/policies-procedures', state: { country: 'scotland' } })
                toggle2('3');
            } else if (key === 'ofsted') {
                props.history.push({ pathname: '/policies-procedures', state: { country: 'england', category: 'children-s-home' } })
                toggle2('4');
            } else if (key === 'rqia') {
                props.history.push({ pathname: '/policies-procedures', state: { country: 'northern-ireland' } })
                toggle2('5');
            }
        },
        changeImage = (id) => {
            setImage(id);
        },
        onHandleIframe = () => {
            var src = "https://share.synthesia.io/embeds/videos/37060810-b05e-4a23-a66f-3e12b300135e"
            dispatch(open_iframe_modal(src))
        };

    let subscriptionLists = subscriptionList.length > 0 && subscriptionList.filter((item, i) => i <= 3 && item);
    let pdfFile = '';
    if (showDocument && showDocument !== undefined) {
        if (showDocument === +1) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Data%20Protection%20Policy-1640250879972.pdf'
        if (showDocument === +2) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/PPE%20Infection%20Control%20Policy-1640251096922.pdf'
        if (showDocument === +3) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Social%20Value%20Policy-1640251345792.pdf'
        if (showDocument === +4) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Admissions%20Policy-1640251539639.pdf'
        if (showDocument === +5) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Reference%20Form-1640251737704.pdf'
        if (showDocument === +6) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Assessment%20of%20a%20Service%20User%E2%80%99s%20Mental%20Capacity-1640251888394.pdf'
        if (showDocument === +7) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Risk%20Assessment-1640251983627.pdf'
        if (showDocument === +8) pdfFile = 'https://www.cloudoc.co.uk/uploads/documents/Interview%20Form-1640252040980.pdf'
    }

    const settingss = {
        dots: false,
        slidesToShow: 5,
        centerMode: true,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        speed: 2000,
        arrow: false,
        autoplaySpeed: 2000,
        // centerPadding: "70px", 
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const url = "https://calendly.com/d/dpp-jhs-cxx/cloudoc-system-demo";
    const prefill = {}
    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
    }
    const utm = {
        utmCampaign: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
        utmTerm: ''
    }
    const openPopup = () => openPopupWidget({ url, prefill, pageSettings, utm })
    return (
        <Fragment>
            <Helmet>
                <title>Policies And Procedures For Supported Living | ClouDoc</title>
                <meta name='keyword' content='Policies And Procedures, Home Care, Supported Living, Semi Independent Living, Children Home' />
                <meta name="description" content="A Cloud document management system gives your business access to all file types, allowing you to be more efficient, productive & organized. Get started now" data-rh="true" />
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            {/*  Quality Assurance and Compliance */}
            <section className="new-banner">
                <div className="container">
                    <div className="col-lg-8 col-md-8 d-flex justify-content-center flex-wrap banner-cnt">
                        <h2 className="d-flex justify-content-center"><span>Quality Assurance and Compliance</span> for Care <p>Providers<img src={bussiness} /></p></h2>
                        <em>Say "Hello!<img src={hand} />" to what you have been looking for</em>
                        <p style={{fontSize:'20px'}}>The most comprehensive and compliant management policies, procedures, documents and forms for the health and social care sector on a convenient document management system.</p>
                        <p style={{fontWeight:'600',width:'100%'}}><img className='fivestarrating' style={{width:'70px'}} src={process.env.PUBLIC_URL + "/assets/images/5starrating.svg"}/>&nbsp; Rated 4.97/5 from over 50 reviews.</p>
                    <div className="buttons">
                            <button className='demo-but' onClick={() => openPopup()}>Book a Demo&nbsp;</button>
                            <button className="green-but" onClick={() => props.history.push('/policies-procedures')}>Buy Now <span className='btn-arrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="banner-bottom">
                <div className="container">
                    <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12 left-img" style={{position:'relative',overflow:'hidden',aspectRatio:'1920/1080',flex:'0 0 100%',maxWidth:'89.5%',margin:'auto'}}>
                        {/* <img src={leftimg} /> */}
                        <iframe
                        loading="lazy"
          src="https://share.synthesia.io/embeds/videos/42e6dc3c-a12b-4a39-b1a6-3b3a27a4f3db"
          width="100%"
          height="600px"
          title="SCORM Player"
          allow="encrypted-media; fullscreen;"
          allowFullScreen
          style={{border:'none'}}
        ></iframe>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-img">
                        <img src={rightimg} />
                    </div> */}
                    <div className="banner-bottom-links">
                        <div className="links-left">
                            <p>Helping you set outstanding quality standards and meet National Standards, Local Authority and Regulators' requirements, including:</p>
                        </div>
                        <div className="links-tabs">
                            <ul>
                                <li onClick={() => gotopage('cqc')}>CQC</li>
                                <li onClick={() => gotopage('ciw')}>CIW</li>
                                <li onClick={() => gotopage('cis')}>CIS</li>
                                <li onClick={() => gotopage('ofsted')}>OFSTED</li>
                                <li onClick={() => gotopage('rqia')}>RQIA</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            <section className="care-business">
                <div className="d-flex container">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 care-left">
                        <img src={careleft} />
                    </div>
                    <div className='hom-buttons'>
                    <div className="buttons">
                            <button className="orange-but our_document" onClick={() => onHandleIframe()}> Our Documents&nbsp;<i class="fa fa-play-circle-o"></i></button>
                            <button className="blue-but" onClick={() => props.history.push('/features')}>System Features</button>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 care-right">
                        <h3>Exactly what you need for your <span>Care Business</span></h3>
                        <p>Stay compliant and worry-free with our reliable, comprehensive policies and management forms. Always up-to-date, quality checked and customisable to your business needs, yet affordable.</p>
                        <div className="care-business-sec">
                            <div className="care-business-part">
                                <span><img src={c1} /></span>
                                <b>Well Written Policies</b>
                                <p>All our policy templates are crafted by industry and legal experts</p>
                            </div>
                            <div className="care-business-part">
                                <span><img src={c2} /></span>
                                <b>Management Forms</b>
                                <p>Designed for ease of use, clean and covering all basics</p>
                            </div>
                            <div className="care-business-part">
                                <span><img src={c3} /></span>
                                <b>Already Customised</b>
                                <p>Skip adding your logo and business details, it’s already done for you</p>
                            </div>
                            <div className="care-business-part">
                                <span><img src={c4} /></span>
                                <b>Always Up To Date</b>
                                <p>Every document gets reviewed and updated in line with any legal changes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="new-quality">
                <div className="container">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 quality-left-side">
                        <div className="quality-left-cnt">
                            <h2>Quality Management <span>and Compliance</span></h2>
                            <p>Stay compliant at the click of a button. ClouDoc ensures you meet the standards and regulations that are relevant to your business. Our documents are always up to date and your business benefits from that. No need to research and scratch your head, our professionals do that work for you so you focus on what’s important. We help you get ready for registration and inspections from regulators including CQC, CIW, RQIA and Care Inspectorate Scotland.</p>
                            <p> All documents are well-written and laid out with all the relevant parameters, as required by the regulators and as recommended by quality standards agencies.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bottom-border"></div>
            <section className="new-features">
                <div className="container">
                    <div className="new-head">
                        <h2><span>System</span> Features</h2>
                        <p>Our Cloud Document Management System is designed to help you manage all your company documents in one place: accessible from anywhere, armed with an exhaustive list of features, including document editor, file uploads, folder management and more. </p>

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 accordion-parts" id="accordionsec">
                        <div className="card">
                            <div className="card-header" id="featureOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#featuresOne" aria-expanded="true" aria-controls="featuresOne" onClick={() => changeImage(1)}>
                                        User Management
                                    </button>
                                </h2>
                            </div>

                            <div id="featuresOne" className="collapse show" aria-labelledby="featuresOne" data-parent="#accordionsec">
                                <div className="card-body">
                                    <span><img src={tabicon} /></span>
                                    <p>Add users to the system to access, manage and control your documents. Allow custom permissions for each user and user groups.</p>

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="featureTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#featuresTwo" aria-expanded="false" aria-controls="featuresTwo" onClick={() => changeImage(2)}>
                                        Quality Management
                                    </button>
                                </h2>
                            </div>
                            <div id="featuresTwo" className="collapse" aria-labelledby="featuresTwo" data-parent="#accordionsec">
                                <div className="card-body">
                                    <span><img src={tabicon} /></span>
                                    <p>Improve your document workflow to ensure document quality is consistently maintained by tasking relevant users.</p>

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="featureThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#featuresThree" aria-expanded="false" aria-controls="featuresThree" onClick={() => changeImage(3)}>
                                        Access control
                                    </button>
                                </h2>
                            </div>
                            <div id="featuresThree" className="collapse" aria-labelledby="featuresThree" data-parent="#accordionsec">
                                <div className="card-body">
                                    <span><img src={tabicon} /></span>
                                    <p>Allow documents to be accessible, publicly and/or user/user groups based on flexible permissions you set.</p>

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="featurefour">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#featuresfour" aria-expanded="false" aria-controls="featuresfour" onClick={() => changeImage(4)}>
                                        Folder Management
                                    </button>
                                </h2>
                            </div>
                            <div id="featuresfour" className="collapse" aria-labelledby="featuresfour" data-parent="#accordionsec">
                                <div className="card-body">
                                    <span><img src={tabicon} /></span>
                                    <p>A convenient folder management tool to help you keep your documents and files organised. Never lose a document, access your files from anywhere.</p>

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="featurefive">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#featuresfive" aria-expanded="false" aria-controls="featuresfive" onClick={() => changeImage(5)}>
                                        Document Download
                                    </button>
                                </h2>
                            </div>
                            <div id="featuresfive" className="collapse" aria-labelledby="featuresfive" data-parent="#accordionsec">
                                <div className="card-body">
                                    <span><img src={tabicon} /></span>
                                    <p>Documents ready for use in any way you want them, they can either be downloaded as pdf or MS Word.</p>

                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" id="featuresix">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#featuressix" aria-expanded="false" aria-controls="featuressix" onClick={() => changeImage(6)}>
                                        Employee App
                                    </button>
                                </h2>
                            </div>
                            <div id="featuressix" className="collapse" aria-labelledby="featuressix" data-parent="#accordionsec">
                                <div className="card-body">
                                    <span><img src={tabicon} /></span>
                                    <p>Effortlessly manage and track company documents, ensuring employee compliance with real-time notifications, secure access, and confirmation quizzes.</p>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 accordion-right">
                        <div  className={`home-pg-accordion ${imgVal === 6 ? 'home-pg-accordionImage' : ''}`}>
                        <img src={imgVal === 1 ? feature1 : imgVal === 2 ? feature2 : imgVal === 3 ? feature3 : imgVal === 4 ? feature4 : imgVal === 5 ? feature5 : imgVal === 6 && mobilefeature6} alt='features'/>
                        {imgVal === 6 && (
                            <>
                            <img src={mobilefeature} alt='features' />
                            <img src={mobilefeature2} alt='features' />
                            </>
                        )}
                        </div>
                    </div>
                </div>
            </section>
            <div className='book-demo-home'>
            <Button className='book-demo-button-home' onClick={() => openPopup()}>Book a Demo&nbsp;</Button>
            </div>
            {
                offerOn ? <section className="offer-sec">
                    <div className="container">
                        <div className="left-offer">
                            <span className="exclusive">EXCLUSIVE OFFER</span>
                            <span className="valid">VALID TILL {moment(packageValidDate ? packageValidDate : trailValidDate).format('DD MMM YYYY')}</span>
                            <span className="grap-upto">GRAB UP TO</span>
                            <span className={`${isPackageOffer ? 'discount percent' : 'discount'}`}>{isPackageOffer ? `${packageDiscount}%` : isTrailOffer ? `${trailDiscount[0]} Month` : ''}</span>
                            <span className="selected">{isPackageOffer ? 'ON SELECTED PACKAGES' : 'FREE TRIAL'}</span>
                        </div>
                        <div className="right-offer">
                            {isPackageOffer ?
                                <div className="two-offers">
                                    {offerSlugs && offerSlugs.length > 0 && offerSlugs.map((item, i) => {
                                        return <div className="offers-card" key={i}>
                                            <span>{isPackageOffer ? `${packageDiscount}%` : ''}<i>off</i></span>
                                            <p>{item.name ? item.name : ''}</p>
                                            <a href={`/subscription-detail/${item.slug}`}>View Package <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    })}
                                </div>
                                : isTrailOffer ?
                                    <div className="one-offers">
                                        <span className="">Policy Updates And Documents management System</span>
                                        <div className="free">
                                            <span>{trailDiscount[0]} Month Free</span>
                                            <a href="/policies-procedures" className="avil">Avail Now <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    : null}
                        </div>
                    </div>
                </section> : null
            }
            {/* <div className="bottom-border"></div> */}
            <div className="doc-form">
                <h5><span>Sample</span> Documents & Forms</h5>
                <div className="container">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 poly-doc">

                        <div className="feature-head">
                            <h2>Policy<span> Documents</span></h2>
                            <p>Our policy templates are well-written and well-laid-out to meet your requirements as well as compliance needs. Whether you are registering or keeping up with the sector changes, look no further.</p>
                        </div>
                        <ul>
                            <li >
                                <a href onClick={() => dispatch(documentModalView(1))}>
                                    <img src={policy_1} alt="Care UK Policies And Procedures" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(2))}>
                                    <img src={policy_2} alt="Care Home Policy UK" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(3))}>
                                    <img src={policy_3} alt="Care Home Policies" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(4))}>
                                    <img src={policy_4} alt="Care Home Policies UK" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                        </ul>
                        <div className="dwnd-pol down-free">
                            <Link to={'/download/sample/documents'}>Download Free Policies <i className="fa fa-download" aria-hidden="true"></i></Link>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 poly-doc">
                        <div className="feature-head">
                            <h2 className="record">Record<span> Forms</span></h2>
                            <p>Well-designed, easy-to-use and customisable record forms to help your business meet its quality assurance requirements. We update our forms regularly so you stay up-to-date and provide good quality services.</p>
                        </div>
                        <ul>
                            <li >
                                <a href onClick={() => dispatch(documentModalView(5))}>
                                    <img src={record_1} alt="Health Care Record Forms" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(6))}>
                                    <img src={record_2} alt="Record Forms" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(7))}>
                                    <img src={record_3} alt="Care Record Forms" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                            <li>
                                <a href onClick={() => dispatch(documentModalView(8))}>
                                    <img src={record_4} alt="Customisable Record Forms" />
                                    <p>Preview Document</p>
                                </a>
                            </li>
                        </ul>
                        <div className="dwnd-pol buy-record">
                            <Link to="/policies-procedures" >Buy Record Forms <i className="fa fa-file-text" aria-hidden="true"></i></Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="bottom-border"></div>

            <section className="plan-price">
                <div className="container">
                    <div className="head-content">
                        <div className="left-pack">
                            <h2><span>Packages</span> and Pricing</h2>
                            <div className="view-all">
                                <button onClick={() => props.history.push("/policies-procedures")}>View All <i className="fa fa-angle-right" aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <p>Our pricing is designed to suit your needs. You can purchase a one-off documents licence or get a discounted licence price when you purchase an updates subscription and or document management system.</p>
                    </div>
                    <div className="plan-sec">
                        {subscriptionLists && subscriptionLists.length > 0 && subscriptionLists.map((item, i) => (
                            <div className="plan-part" key={i}>
                                <h3>{item.name}</h3>
                                <div className="plan-doc">

                                    <div>
                                        <p>Documents Included</p>
                                        <p className="doc-num">{item.files}</p>
                                    </div>
                                    <span><img src={price_1} alt="subscription" /></span>
                                    <span className="box-hover"><img src={price_1_h} alt="subscription" /></span>
                                </div>
                                <div className="plan-doc">

                                    <div>
                                        <p>Updates Option Available</p>
                                    </div>
                                    <span><img src={refresh_1} alt="subscription" /></span>
                                    <span className="box-hover"><img src={refresh_1_h} alt="subscription" /></span>
                                </div>
                                <div className="plan-price-sec">
                                    <p>From</p>
                                    <h4>£
                                        {settingsData.discountStatus === 1 ? Number(item.licenseFee - item.licenseFee * settingsData.yearlyDiscount / 100).toFixed(2) : item.licenseFee}
                                    </h4>
                                    <button onClick={(e) => addToCart(e, item._id, "add-to-cart", item)}>Add To Cart <i className="fa fa-cart-plus" aria-hidden="true"></i></button>
                                    <div onClick={() => props.history.push(`/subscription-detail/${item.slug}`)} className="view-details">View Details</div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
            <div className='book-demo-home'>
            <Button className='book-demo-button-home' onClick={() => openPopup()}>Book a Demo&nbsp;</Button>
            </div>
            <div className="bottom-border"></div>
            <section className="latest-document">
                <div className="container">
                    <div className="head-content">
                        <h2><span>Latest</span> Documents Updates</h2>
                        <p>We review documents on our platform regularly to meet legal, regulators/compliance, quality improvement needs and more. View our latest updates below:</p>
                    </div>
                    {documentList && documentList.length > 0 && documentList.map((item, i) => (
                        <div className="latest-part" key={i}>
                            <p>{item.filename}</p>
                            <span>
                                <span>View Details</span>
                                {moment(item.updatedAt).format("DD MMM YYYY")}</span>
                        </div>
                    ))}
                    <div className="view-all">
                        <button onClick={() => props.history.push("/document-updates")}>View All</button>
                    </div>
                </div>
            </section>
            <div className="bottom-border"></div>
            <section className="updates">
                <div className="container">
                    <h2>New <span>Updates</span></h2>
                    <div className="view-all">
                        <a href={settingsData && settingsData.blog_url && settingsData.blog_url !== '' && settingsData.blog_url !== undefined ? settingsData.blog_url : '#'} target='_self'><button>View All <i className="fa fa-angle-right" aria-hidden="true"></i></button></a>
                    </div>
                    <div className="updates-sec">
                        {userBlogLists && userBlogLists.length > 0 && userBlogLists.map((item, i) => (
                            <div className="update-part wow fadeInUp" key={i}>
                                <span className='up-image'>
                                    {item.featured_media ? <a href={item.link} target='_self'><img src={item._embedded['wp:featuredmedia'][0] && item._embedded['wp:featuredmedia'][0].media_details.sizes["large"] && item._embedded['wp:featuredmedia'][0].media_details.sizes["large"] && item._embedded['wp:featuredmedia'][0].media_details.sizes["large"] !== undefined && item._embedded['wp:featuredmedia'][0].media_details.sizes["large"].source_url ? item._embedded['wp:featuredmedia'][0].media_details.sizes["large"].source_url : item._embedded['wp:featuredmedia'][0].source_url ? item._embedded['wp:featuredmedia'][0].source_url : update_bac} /></a> : null}
                                    <p className="blog-cat">{item.categories && item.categories.length > 0 ? <a href={item.link} target='_blank'>{item._embedded['wp:term'][0][0].name}</a> : null}</p>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </span>
                                <div className="up-title">
                                    <p>Posted On: <Moment format="DD MMM YYYY">{new Date(item.date ? item.date : '')}</Moment></p>
                                </div>
                                <h3><a href={item.link} target='_self' dangerouslySetInnerHTML={{ __html: item.title.rendered }} /></h3>
                                {item.content.rendered ? <div className="up-content" dangerouslySetInnerHTML={{ __html: item.content.rendered }} /> : null}
                                <span className='read-more-sec'><a href={item.link} target='_self'>Read More <i className="fa fa-angle-right" aria-hidden="true"></i></a></span>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
            <div className="bottom-border"></div>
            <section className="happy-client">
                <div className="container">
                    <div className="feature-head">
                        <h2>Happy<span> Clients</span></h2>
                    </div>

                    {/* <ul>
                        <li><img src={client_1} alt='client' /></li>
                        <li><img src={client_2} alt='client' /></li>
                        <li><img src={client_3} alt='client' /></li>
                        <li><img src={client_4} alt='client' /></li>
                        <li><img src={client_7} alt='client' /></li>
                        <li><img src={client_8} alt='client' /></li>
                    </ul> */}
                    <div>
                        <Slider {...settingss}>
                            <div className="happy-clients">
                                <img src={client_1} alt='client' />
                            </div>
                            <div className="happy-clients">
                                <img src={client_2} alt='client' />
                            </div>
                            <div className="happy-clients">
                                <img src={client_3} alt='client' />
                            </div>
                            <div className="happy-clients">
                                <img src={client_4} alt='client' />
                            </div>
                            <div className="happy-clients">
                                <img src={client_7} alt='client' />
                            </div>
                            <div className="happy-clients">
                                <img src={client_8} alt='client' />
                            </div>

                        </Slider>
                    </div>
                </div>
            </section>
            <div className="bottom-border"></div>
            <section className="faq">
                <div className="container">
                    <div className="faq-title">
                        <h2><span>Frequently</span> Asked Questions</h2>
                    </div>
                    <div className="faq-sec">
                        <div className={classes.root} id="faq-accord">
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                indicatorColor="none"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                className={classes.tabs}
                            >
                                <Tab label="System Features" {...a11yProps(0)} />
                                <Tab label="Document Updates" {...a11yProps(1)} />
                                <Tab label="Subscription & Pricing" {...a11yProps(2)} />
                                <Tab label="General" {...a11yProps(3)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <div className="accordion" id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="head-one">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-one" aria-controls="collapse-one">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    How often do policy templates get updated?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-one" className='collapse' aria-labelledby="head-one" data-parent="#accordion">
                                            <div className="card-body">
                                                Our experienced team of policy writers continuously review all policies on ClouDoc to ensure they are up to date. We continuously monitor changes in regulations, advice and quality standards and make relevant changes to our policy templates.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="head-two">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-two" aria-expanded={`false`} aria-controls="collapse-two"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Can I share my documents with other people?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-two" className='collapse' aria-labelledby="head-two" data-parent="#accordion">
                                            <div className="card-body">
                                                With ClouDoc, you can share your documents as PDFs with a link, via email or on a page on your website. Also, you can add users to your account and have them access specific folders and files on the application.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="head-three">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-three" aria-expanded={`false`} aria-controls="collapse-three"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Can I add my own documents on the system?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-three" className='collapse' aria-labelledby="head-three" data-parent="#accordion">
                                            <div className="card-body">
                                                Yes. If you have subscribed for the document management system you can upload and create, save and manage your own documents on the system.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="accordion" id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="head-four">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-four" aria-expanded={`false`} aria-controls="collapse-four"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Can I download the documents in Word?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-four" className='collapse' aria-labelledby="head-four" data-parent="#accordion">
                                            <div className="card-body">
                                                All documents in the editor can be downloaded as both MS Word and PDF. You can also edit using our online editor, which works similarly to MS Word.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="head-five">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-five" aria-expanded={`false`} aria-controls="collapse-five" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Does my company logo appear on the document?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-five" className='collapse' aria-labelledby="head-five" data-parent="#accordion">
                                            <div className="card-body">
                                                Your company logo is uploaded on the settings section and it will appear on all documents, together with all the other relevant business information set up in the settings.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="head-six">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-six" aria-expanded={`false`} aria-controls="collapse-six"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Do I get notifications when documents are updated?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-six" className='collapse' aria-labelledby="head-six" data-parent="#accordion">
                                            <div className="card-body">
                                                If you have purchased a document update subscription or you are within your first year of subscription, you get email notifications with download links. You also have access to new documents that are added into the document set you have purchased.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="accordion" id="accordion-2">
                                    <div className="card">
                                        <div className="card-header" id="head-six">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-six" aria-expanded={`false`} aria-controls="collapse-six"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Can I add my own documents on the system?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-six" className='collapse' aria-labelledby="head-six" data-parent="#accordion-2">
                                            <div className="card-body">
                                                Yes. If you have subscribed for the document management system you can upload and create, save and manage your own documents on the system.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading_Three">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse_Three" aria-expanded={`false`} aria-controls="collapse_Three"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    How much storage space can I get on the system?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse_Three" className='collapse' aria-labelledby="heading_Three" data-parent="#accordion-2">
                                            <div className="card-body">
                                                Depending on your requirements you can purchase additional storage from 5GB.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading_four">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse_Four" aria-expanded={`false`} aria-controls="collapse_Four" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Do I get notifications when documents are updated?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse_Four" className='collapse' aria-labelledby="heading_four" data-parent="#accordion-2">
                                            <div className="card-body">
                                                If you have purchased a document update subscription or you are within your first year of subscription, you get email notification with download links. You also have access to new documents that are added into the document set you have purchased.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading_five">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse_Five" aria-expanded={`false`} aria-controls="collapse_Five" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Can I add other people to access my documents?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse_Five" className='collapse' aria-labelledby="heading_five" data-parent="#accordion-2">
                                            <div className="card-body">
                                                Yes you can add more users to you account to access your documents
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div className="accordion" id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="head-seven">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-seven" aria-expanded={`false`} aria-controls="collapse-seven" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    How much does it cost to renew my subscription?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-seven" className='collapse' aria-labelledby="head-seven" data-parent="#accordion">
                                            <div className="card-body">
                                                The cost of renewal depends on your subscription and this differs for every subscription package. The renewal price is stated on the detail page for every subscription. It may also be a custom fee depending on the additional services in your subscription.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading-eight">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-eight" aria-expanded={`false`} aria-controls="collapse-eight" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    What documents are included in my subscription?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-eight" className='collapse' aria-labelledby="heading-eight" data-parent="#accordion">
                                            <div className="card-body">
                                                You can view and also download the index of all the documents included in any of the document sets you buy. Check the package detail page for this information.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <div className="accordion" id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="head-nine">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-nine" aria-expanded={`false`} aria-controls="collapse-nine"  >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    How much storage space can I get on the system?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-nine" className='collapse' aria-labelledby="head-nine" data-parent="#accordion">
                                            <div className="card-body">
                                                Depending on your requirements you can purchase additional storage from 5GB.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="head-ten">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapse-ten" aria-expanded={`false`} aria-controls="collapse-ten" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    Can I add other people to access my documents?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapse-ten" className='collapse' aria-labelledby="head-ten" data-parent="#accordion">
                                            <div className="card-body">
                                                Yes, you can add more users to your account to access your documents.
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card">
                                        <div className="card-header" id="headingfive">
                                            <h2 className="mb-0">
                                                <button className={`btn btn-link btn-block text-left collapsed `} type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded={`false`} aria-controls="collapseFive" >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <FontAwesomeIcon icon={faMinus} />
                                                    How much does it cost to renew my subscription
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className='collapse' aria-labelledby="headingfive" data-parent="#accordion">
                                            <div className="card-body">
                                                The cost of renewal depends on your subscription and this differs for every subscription package. The renewal price is stated on the detail page for every subscription. It may also be a custom fee depending on the additional services in your subscription.
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </TabPanel>
                        </div>

                    </div>
                </div>
            </section>
            <div className='book-demo-home'>
            <Button className='book-demo-button-home' onClick={() => openPopup()}>Book a Demo&nbsp;</Button>
            </div>
            {<div className=".progress-ani"></div>}
            <div className="fixed-phone">
                <div className="fixed-phone-sec">
                    <a href="tel:0330 808 0050"><img src={fixedphone} /></a>
                </div>
            </div>
            <ScrollTop />
            <Modal isOpen={showDocumentModal} toggle={() => dispatch(documentModalView())} id="document-view">
                <ModalHeader toggle={() => dispatch(documentModalView())}>PREVIEW DOCUMENT</ModalHeader>
                <ModalBody>
                    <iframe src={pdfFile} frameBorder="0" scrolling="auto" height="100%" width="100%" />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => dispatch(documentModalView())}>Cancel</Button>
                </ModalFooter>
            </Modal>
            {showUnreadPolicyNotify && <PolicyUnreadNotify history={props.history} />}
            {
                offerShowModal ? <OfferModal
                    offerShowModal={offerShowModal}
                    toggleOfferModal={changeOfferModal}
                    settingsData={settingsData}
                    getSlug={getSlugArr}
                    offerSlugs={offerSlugs}
                    offerOn={offerOn}
                    isPackageOffer={isPackageOffer}
                    isTrailOffer={isTrailOffer}
                    packageDiscount={packageDiscount}
                    trailDiscount={trailDiscount}
                    packageValidDate={packageValidDate}
                    trailValidDate={trailValidDate} /> : null
            }

            {viewIframeModal === true ? <IframeModal /> : null}
        </Fragment >
    );
};

export default Home;