import moment from 'moment';
import React from 'react';
import { Modal } from 'reactstrap';
import offer_popup from '../../../images/new-design/offer-popup.png';
import './index.css';

const OfferModal = (props) => {
    const { offerShowModal, toggleOfferModal, settingsData, getSlug, offerSlugs, offerOn, isPackageOffer, isTrailOffer, packageDiscount, trailDiscount, packageValidDate, trailValidDate } = props;
    return <Modal isOpen={offerShowModal} centered={true} toggle={(e) => toggleOfferModal()} backdrop={true} className='offer-container'>
        <div className="offer-model">
            <div className="offer-top">
                <div className="offer-top-less">
                    <p>Grab Up to</p>
                    <h3>{isPackageOffer ? `${packageDiscount}%` : isTrailOffer ? `${trailDiscount[0]} Month` : ''}</h3>
                    <p> {isPackageOffer ? `Offer On Selected Packages` : isTrailOffer ? `Free Trail Subscription` : ''} </p>
                </div>
                <div className="offer-valid">
                    <p>VALID TILL</p>
                    <span className="valid-date">{moment(packageValidDate ? packageValidDate : trailValidDate).format('DD MMM YYYY')}
                    </span>
                </div>
            </div>
            <div className="offer-section">
                <div className="offer-left">
                    <img src={offer_popup} />
                </div>
                <div className="offer-right">
                    {isPackageOffer ? offerSlugs && offerSlugs.length > 0 && offerSlugs.map((item, i) => {
                        return <div className="offer-details" key={i}>
                            <span> {packageDiscount}% Off</span>
                            <p>{item.name}</p>
                            <a href={`/subscription-detail/${item.slug}`}>View Package <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                        </div>
                    }) : isTrailOffer ?
                        <div className="offer-details sub">
                            <span>{trailDiscount[0]} Month Free</span>
                            <p>Policy Updates And Documents Management System</p>
                            <a href="/policies-procedures">Avail Now <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                        </div>
                        : null}
                </div>
            </div>
            <button onClick={() => toggleOfferModal()}> No Thanks!</button>
        </div>
    </Modal>
};

export default OfferModal;
