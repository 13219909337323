import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Tooltip } from '@trendmicro/react-tooltip';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '@trendmicro/react-tooltip/dist/react-tooltip.css';
import { setSidebarExpand } from '../../../actions/uiAction';
import { getSettingsData } from '../../../actions/subscriptionAction';
import { clearClickedFolder, listDocument, documentSearch, clearSearchData } from '../../../actions/documentAction';
import { openPopupWidget } from "react-calendly";

const url = "https://calendly.com/d/djv-dvw-6px/cloudoc-system-training";
const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '00a2ff',
    textColor: '4d5055'
};
const prefill = {};
const utm = {
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: ''
};

const Sidebar = (props) => {

    const dispatch = useDispatch(),
        settingsData = useSelector(state => state.subscriptionData.settingData),
        expanded = useSelector(state => state.UIReducer.sideBarExpanded),
        active_subs = useSelector(state => state.userLoginData.active_subs),
        employeeSubscription = useSelector(state => state.userLoginData.employeeSubscription),
        // Comp_details = useSelector(state => state.userLoginData.Comp_details),
        // dismiss_subs = useSelector(state => state.userLoginData.dismiss_subs),
        // dismiss_comp_details = useSelector(state => state.userLoginData.dismiss_comp_details),
        userDetails = useSelector(state => state.myProfile.addViewData),
        companyData = useSelector(state => state.UIReducer.userDetails);
    useEffect(() => {
        dispatch(getSettingsData());
        sessionStorage.setItem('sideBarSiteExpanded', expanded)
    }, [])

    const SideBarAction = (expanded) => {
        let querySelector = document.querySelector('.right-sec-cnt');
        if (expanded === false) {
            querySelector.classList.add('right-collapsed');
        } else {
            querySelector.classList.remove('right-collapsed');
        }
        sessionStorage.setItem('sideBarSiteExpanded', expanded);
        dispatch(setSidebarExpand(expanded));
    }

    let openPopup = () => openPopupWidget({ url, prefill, pageSettings, utm });
    let isAuthenticated = sessionStorage.getItem("isAuthenticated");
    let user_permission = sessionStorage.getItem('user_permission') ? JSON.parse(sessionStorage.getItem('user_permission')) : [];
    if (props.location.pathname === '/documents' || props.location.pathname === '/dashboard'
        || props.location.pathname === '/subscriptions' || props.location.pathname === '/users'
        || props.location.pathname === '/users/add' || props.location.pathname === '/users/edit' || props.location.pathname === '/users/view'
        || props.location.pathname === '/users/activity' || props.location.pathname === '/saved-documents'
        || props.location.pathname === '/create-new-documents' || props.location.pathname === '/users/deleted-list'
        || props.location.pathname === '/subscription/pay' || props.location.pathname === '/documents/compare'
        || props.location.pathname === '/index' || props.location.pathname === '/documents/recent-updates'
        || props.location.pathname === '/documents/need-review' || props.location.pathname === '/documents/uptodate'
        || props.location.pathname === '/documents/editor' || props.location.pathname === '/settings'
        || props.location.pathname === '/document/management/renewal' || props.location.pathname === '/invoice'
        // || props.location.pathname === '/history' 
        || props.location.pathname === '/groups'
        || props.location.pathname === '/group/add' || props.location.pathname === '/group/edit' || props.location.pathname === '/group/view'
        || props.location.pathname === '/policies' || props.location.pathname === '/policies/edit' || props.location.pathname === '/policies/create'
        || props.location.pathname === '/employees' || props.location.pathname === '/policies/multi/create'
        || props.location.pathname === '/employee/add' || props.location.pathname === '/employee/edit'
        || props.location.pathname === '/employee/view' || props.location.pathname === '/deleted/employees'
        || props.location.pathname === '/myfiles' || props.location.pathname === '/myfiles/editor'
        || props.location.pathname === '/myfiles/details' || props.location.pathname === '/myDocuments' || props.location.pathname === '/myDocuments/editor'
        || props.location.pathname === '/myDocuments/details' || props.location.pathname === '/publishedpolicies'
        || props.location.pathname === '/deleted-documents'
        || (props.location.pathname).includes('/reference/')
    ) {
        var n = props.location.pathname.lastIndexOf('/');
        let activePath = props.location.pathname.substring(n + 1);
        let showTraining = isAuthenticated && isAuthenticated !== undefined && String(isAuthenticated) === String(true) && userDetails && userDetails.createdAt && userDetails.createdAt !== undefined && new Date(userDetails.createdAt).setDate(new Date(userDetails.createdAt).getDate() + 30) > new Date() ? true : false;

        return (
            <SideNav
                onSelect={(selected) => {
                    const to = '/' + selected;
                    if (props.location.pathname !== to) {
                        dispatch(documentSearch(null));
                        dispatch(clearSearchData(null))
                        if (String(props.location.pathname) === String('/documents') || String(to) === String('/documents')) {
                            dispatch(documentSearch(""));
                            dispatch(clearSearchData(null))
                            dispatch(clearClickedFolder());
                            sessionStorage.removeItem('clickedFolderId');
                            sessionStorage.removeItem('clickedFolderUid');
                            sessionStorage.removeItem('clickedFolder');
                            dispatch(listDocument('name', false));
                        }
                        if (String(to) === String('/dashboard') || String("/users") || String("/index")) {
                            dispatch(documentSearch(null));
                            dispatch(clearSearchData(null))
                        }
                        if (to !== '/myfiles' && '/myfiles/editor') {
                            sessionStorage.removeItem('myfilesSelectedFolderId');
                            sessionStorage.removeItem('myfilesId');
                        }
                        if (to !== '/myDocuments' && '/myDocuments/editor') {
                            sessionStorage.removeItem('myfilesSelectedFolderId');
                            sessionStorage.removeItem('myfilesId');
                        }
                        if (String(props.location.pathname) === String('/documents') || String(to) === String('/documents')) {

                        }
                        props.history.push(to);
                    }
                }}
                expanded={expanded}
                onToggle={(expanded) => SideBarAction(expanded)}
            >
                <SideNav.Toggle />
                {expanded && <div className="col-lg-12 col-md-12 logo-company">
                    <Link to={'/dashboard'}><img src={
                        (companyData && companyData.companyDetails && companyData.companyDetails.logoPath)
                            ? (companyData.companyDetails.logoPath.includes('https://www.caredocuments.co.uk/') ? companyData.companyDetails.logoPath.replace('https://www.caredocuments.co.uk/', 'https://www.cloudoc.co.uk/') : companyData.companyDetails.logoPath)
                            : (companyData && companyData.companyLogo)
                                ? companyData.companyLogo
                                : (companyData.docLogoPath) ? companyData.docLogoPath : (settingsData && settingsData.admin_logo) ? settingsData.admin_logo : process.env.PUBLIC_URL + "/assets/images/logo.png"} alt='logo' /></Link>
                </div>}
                <SideNav.Nav defaultSelected={activePath}>
                    {
                        sessionStorage.getItem('userRole') === 'company' ?
                            <NavItem eventKey="dashboard">
                                <NavIcon><Tooltip placement="right" content="Dashboard" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/dashboard.svg"} style={{ fontSize: '1.75em' }} alt='dashboard' /><img src={process.env.PUBLIC_URL + "/assets/images/dashboard-g.svg"} style={{ fontSize: '1.75em' }} alt='dashboard' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText> Dashboard </NavText>
                            </NavItem>
                            : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Dashboard') || String(e.name) === String('Manage users')).length > 0 ?
                                <NavItem eventKey="dashboard">
                                    <NavIcon><Tooltip placement="right" content="Dashboard" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/dashboard.svg"} style={{ fontSize: '1.75em' }} alt='dashboard' /><img src={process.env.PUBLIC_URL + "/assets/images/dashboard-g.svg"} style={{ fontSize: '1.75em' }} alt='dashboard' className='green-svg' /></span></Tooltip></NavIcon>
                                    <NavText> Dashboard </NavText>
                                </NavItem>
                                : null
                    }
                    {String(active_subs) !== String(false) &&
                        sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="documents">
                            <NavIcon><Tooltip placement="right" content="Documents" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-menu.svg"} style={{ fontSize: '1.75em' }} alt="Documents" /><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-g.svg"} style={{ fontSize: '1.75em' }} alt="Documents" className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText title="Documents"> Documents </NavText>
                        </NavItem>
                        : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Documents') || String(e.name) === String('Manage users')).length > 0 ?
                            <NavItem eventKey="documents">
                                <NavIcon><Tooltip placement="right" content="Documents" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-menu.svg"} style={{ fontSize: '1.75em' }} alt="Documents" /><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-g.svg"} style={{ fontSize: '1.75em' }} alt="Documents" className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="Documents"> Documents </NavText>
                            </NavItem>
                            : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'employee' && user_permission.filter(e => String(e.name) === String('Documents') || String(e.name) === String('Manage users')).length > 0 ?
                                <NavItem eventKey="documents">
                                    <NavIcon><Tooltip placement="right" content="Documents" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-menu.svg"} style={{ fontSize: '1.75em' }} alt="Documents" /><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-g.svg"} style={{ fontSize: '1.75em' }} alt="Documents" className='green-svg' /></span></Tooltip></NavIcon>
                                    <NavText title="Documents"> Documents </NavText>
                                </NavItem>
                                : null
                    }
                    {String(active_subs) !== String(false) &&
                        sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="myDocuments">
                            <NavIcon><Tooltip placement="right" content="My Documents" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/myfiles_folder.svg"} style={{ fontSize: '1.75em' }} alt='mydocuments_folder' /><img src={process.env.PUBLIC_URL + "/assets/images/myfiles_folder-g.svg"} style={{ fontSize: '1.75em' }} alt='myfiles_folder' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText title="My Documents"> My Documents </NavText>
                        </NavItem>
                        : String(active_subs) !== String(false) &&
                            sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('My Documents') || String(e.name) === String('My Documents')).length > 0 ?
                            <NavItem eventKey="myDocuments">
                                <NavIcon><Tooltip placement="right" content="My Documents" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/myfiles_folder.svg"} style={{ fontSize: '1.75em' }} alt='mydocuments_folder' /><img src={process.env.PUBLIC_URL + "/assets/images/myfiles_folder-g.svg"} style={{ fontSize: '1.75em' }} alt='myfiles_folder' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="My Documents"> My Documents </NavText>
                            </NavItem> : null
                    }
                    {companyData && companyData.proPackage && companyData.proPackage.status && companyData.proPackage.status === 1 && String(active_subs) !== String(false) &&
                        sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="myfiles">
                            <NavIcon><Tooltip placement="right" content="DocuSign" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/DocSign.svg"} style={{ fontSize: '1.75em' }} alt='myfiles_folder' /><img src={process.env.PUBLIC_URL + "/assets/images/DocSign-g.svg"} style={{ fontSize: '1.75em' }} alt='myfiles_folder' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText title="DocuSign"> DocuSign </NavText>
                        </NavItem>
                        : String(active_subs) !== String(false) &&
                            sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('DocuSign') || String(e.name) === String('DocuSign')).length > 0 ?
                            <NavItem eventKey="myfiles">
                                <NavIcon><Tooltip placement="right" content="DocuSign" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/DocSign.svg"} style={{ fontSize: '1.75em' }} alt='myfiles_folder' /><img src={process.env.PUBLIC_URL + "/assets/images/DocSign-g.svg"} style={{ fontSize: '1.75em' }} alt='myfiles_folder' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="DocuSign"> DocuSign </NavText>
                            </NavItem> : null
                    }
                    {/* {(sessionStorage.getItem('userRole') === 'company' && employeeSubscription === 'active') ? */}

                    {/* {String(active_subs) !== String(false) && (sessionStorage.getItem('userRole') === 'company' && employeeSubscription === 'active') ? */}
                    {String(active_subs) !== String(false) && (sessionStorage.getItem('userRole') === 'company') ?
                        <NavItem eventKey="employees">
                            <NavIcon><Tooltip placement="right" content="Employees" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/employees.svg"} style={{ fontSize: '1.75em' }} alt='employees' /><img src={process.env.PUBLIC_URL + "/assets/images/employees-g.svg"} style={{ fontSize: '1.75em' }} alt='employees' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText title="employees"> Employees </NavText>
                        </NavItem>
                        : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Employees') || String(e.name) === String('Manage employee')).length > 0 ?
                            <NavItem eventKey="employees">
                                <NavIcon><Tooltip placement="right" content="Employees" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/employees.svg"} style={{ fontSize: '1.75em' }} alt='employees' /><img src={process.env.PUBLIC_URL + "/assets/images/employees-g.svg"} style={{ fontSize: '1.75em' }} alt='employees' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="employees"> Employees </NavText>
                            </NavItem> : null
                    }
                    {/* {(String(active_subs) !== String(false) && employeeSubscription === 'active') && sessionStorage.getItem('userRole') === 'company' ? */}
                    {/* {String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="groups">
                            <NavIcon><Tooltip placement="right" content="Groups" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/employeegroups.svg"} style={{ fontSize: '1.75em' }} alt='groups' /><img src={process.env.PUBLIC_URL + "/assets/images/employeegroups-g.svg"} style={{ fontSize: '1.75em' }} alt='groups' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText title="groups"> Groups </NavText>
                        </NavItem>
                        : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Groups') || String(e.name) === String('Manage groups')).length > 0 ?
                            <NavItem eventKey="groups">
                                <NavIcon><Tooltip placement="right" content="Groups" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/employeegroups.svg"} style={{ fontSize: '1.75em' }} alt='groups' /><img src={process.env.PUBLIC_URL + "/assets/images/employeegroups-g.svg"} style={{ fontSize: '1.75em' }} alt='groups' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="groups"> Groups </NavText>
                            </NavItem> : null
                    } */}

                                        {(sessionStorage.getItem('userRole') === 'company') ?
                        <NavItem eventKey="policies">
                            <NavIcon><Tooltip placement="right" content="Policies" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy.svg"} style={{ fontSize: '1.75em' }} alt='policies' /><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy-g.svg"} style={{ fontSize: '1.75em' }} alt='users' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText> Staff Policies </NavText>
                        </NavItem>
                        // : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' ?
                        : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Policies') || String(e.name) === String('Manage users')).length > 0 ?
                            <NavItem eventKey="policies">
                                <NavIcon><Tooltip placement="right" content="policies" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy.svg"} style={{ fontSize: '1.75em' }} alt='policies' /><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy-g.svg"} style={{ fontSize: '1.75em' }} alt='users' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText> Staff Policies </NavText>
                            </NavItem>
                            : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'employee' ?
                                <NavItem eventKey="policy-documents">
                                    <NavIcon><Tooltip placement="right" content="policies" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy.svg"} style={{ fontSize: '1.75em' }} alt='policies' /><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy-g.svg"} style={{ fontSize: '1.75em' }} alt='users' className='green-svg' /></span></Tooltip></NavIcon>
                                    <NavText> Staff Policies </NavText>
                                </NavItem>
                                : null
                    }

                    {(sessionStorage.getItem('userRole') === 'company' && companyData && companyData.settings && companyData.settings.showPoliciesonSite && (companyData.comboPackage && companyData.comboPackage.status === 1 || companyData.documentManagement && companyData.documentManagement.status === 1 || companyData.documentUpdate && companyData.documentUpdate.status === 1 || companyData.proPackage && companyData.proPackage.status === 1)) ?
                        <NavItem eventKey="publishedpolicies">
                            <NavIcon><Tooltip placement="right" content="PublicPolicies" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy.svg"} style={{ fontSize: '1.75em' }} alt='policies' /><img src={process.env.PUBLIC_URL + "/assets/images/emp_policy-g.svg"} style={{ fontSize: '1.75em' }} alt='users' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText> Public Policies </NavText>
                        </NavItem>
                        : null
                    }
                                        {String(active_subs) !== String(false) &&
                        sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="users">
                            <NavIcon><Tooltip placement="right" content="Users" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/user.svg"} style={{ fontSize: '1.75em' }} alt='users' /><img src={process.env.PUBLIC_URL + "/assets/images/user-g.svg"} style={{ fontSize: '1.75em' }} alt='users' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText> System Users </NavText>
                        </NavItem> :
                        String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Users') || String(e.name) === String('Manage users')).length > 0 ?
                            <NavItem eventKey="users">
                                <NavIcon><Tooltip placement="right" content="Users" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/user.svg"} style={{ fontSize: '1.75em' }} alt='users' /><img src={process.env.PUBLIC_URL + "/assets/images/user-g.svg"} style={{ fontSize: '1.75em' }} alt='users' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText>System Users </NavText>
                            </NavItem> : null
                    }
                    {String(active_subs) !== String(false) &&
                        sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="index">
                            <NavIcon><Tooltip placement="right" content="Index" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/index.svg"} style={{ fontSize: '1.75em' }} alt='index' /><img src={process.env.PUBLIC_URL + "/assets/images/index-g.svg"} style={{ fontSize: '1.75em' }} alt='index' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText> Index </NavText>
                        </NavItem> : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Index') || String(e.name) === String('Manage groups')).length > 0 ?
                            <NavItem eventKey="index">
                                <NavIcon><Tooltip placement="right" content="Index" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/index.svg"} style={{ fontSize: '1.75em' }} alt='index' /><img src={process.env.PUBLIC_URL + "/assets/images/index-g.svg"} style={{ fontSize: '1.75em' }} alt='index' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="index"> Index </NavText>
                            </NavItem> : null
                    }
                    {/* {String(active_subs) !== String(false) &&
                        sessionStorage.getItem('userRole') === 'company' ?
                        <NavItem eventKey="history">
                            <NavIcon><Tooltip placement="right" content="History" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/history.svg"} style={{ fontSize: '1.75em' }} alt='index' /><img src={process.env.PUBLIC_URL + "/assets/images/history-g.svg"} style={{ fontSize: '1.75em' }} alt='index' className='green-svg' /></span></Tooltip></NavIcon>
                            <NavText> History </NavText>
                        </NavItem> : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('History') || String(e.name) === String('Manage users')).length > 0 ?
                            <NavItem eventKey="history">
                                <NavIcon><Tooltip placement="right" content="History" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/add-folder-menu.svg"} style={{ fontSize: '1.75em' }} alt="History" /><img src={process.env.PUBLIC_URL + "/assets/images/history-g.svg"} style={{ fontSize: '1.75em' }} alt="History" className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText title="History"> History </NavText>
                            </NavItem> : null
                    } */}
                    {
                        sessionStorage.getItem('userRole') === 'company' ?
                            <NavItem eventKey="deleted-documents">
                                <NavIcon><Tooltip placement="right" content="Bin" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/delete-icon.svg"} style={{ fontSize: '1.75em' }} alt='bin' /><img src={process.env.PUBLIC_URL + "/assets/images/delete-g.svg"} style={{ fontSize: '1.75em' }} alt='bin' className='green-svg' /></span></Tooltip></NavIcon>
                                <NavText> Bin </NavText>
                            </NavItem>
                            : String(active_subs) !== String(false) && sessionStorage.getItem('userRole') === 'user' && user_permission.filter(e => String(e.name) === String('Manage users') || String(e.name) === String('deleted-documents')).length > 0 ?
                                <NavItem eventKey="deleted-documents">
                                    <NavIcon><Tooltip placement="right" content="Bin" disabled={expanded}><span><img src={process.env.PUBLIC_URL + "/assets/images/delete-icon.svg"} style={{ fontSize: '1.75em' }} alt='bin' /><img src={process.env.PUBLIC_URL + "/assets/images/delete-g.svg"} style={{ fontSize: '1.75em' }} alt='bin' className='green-svg' /></span></Tooltip></NavIcon>
                                    <NavText> Bin </NavText>
                                </NavItem>
                                : null
                    }
                </SideNav.Nav>
                <div className='fixed-buttons'>
                    {showTraining && <div className='training-btn'>
                        <Tooltip placement="top" content="Book Training">
                            <button onClick={openPopup}><i className="fa fa-file-text" aria-hidden="true"></i>Book Training</button>
                        </Tooltip>
                    </div>}
                    {/* <div className='help-btn'><Tooltip placement="top" content='Help'><a href='https://app.tango.us/app/embed/43aef331-ddd7-4d26-aac9-a2630ad8c0fc?iframe%22' target='_blank'><div className='bouuton-help'>?</div></a></Tooltip></div> */}
                </div>
            </SideNav>
        )
    } else {
        return null;
    }
}

export default Sidebar;