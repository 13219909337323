import React, { useEffect, useState, useRef } from "react";
import { AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import classnames from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Dropdown, DropdownMenu, DropdownToggle, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Tooltip, DropdownItem } from "reactstrap";
import Backdrop from '@material-ui/core/Backdrop';
import Badge from '@material-ui/core/Badge';
import { grey } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { openPopupWidget } from "react-calendly";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import io from "socket.io-client";
import { dismiss_msg_pop, IMPORT_FROM_LOCAL } from "../../../actions/cartAction";
import { get_comp_details_keys } from "../../../actions/dashboardAction";
import { restoreDocumentToCompany } from "../../../actions/documentAction";
import { viewProfile } from "../../../actions/myProfileAction";
import { categoryList, Clear_subs_Data, DISMIS, DISMIS_1, getSettingsData, getSubscriptionLists, listSubscription } from "../../../actions/subscriptionAction";
import { closeErrorMsg, getCartCount, getErrorMsg, getUserDetails, logOut, LogOutModalView, restoreModalView, setDropdownOpen, mobileModalFunc, subExpiryNotifyToggle } from "../../../actions/uiAction";
import { addLoginData, adminUserLogin, authModal, clearFormData, createNewPassword, forgotModal, forgotPassword, getCompanyCredetials, newPwdModal, otpModal, otpSubmit, setActiveTab, SHow_pop_for_login, signInModal, userLogin } from "../../../actions/userLoginAction";
import company from "../../../images/new-design/account/company.svg";
import userIcon from "../../../images/new-design/account/mail.svg";
import name from "../../../images/new-design/account/name.svg";
import passwordIcon from "../../../images/new-design/account/password.svg";
import logo from "../../../images/new-design/site-logo.svg";
import ReCAPTCHA from 'react-google-recaptcha'
// import "../../../index.css";
import BottomModal from "../../../modules/Common/bottommodal";
import RestoreModal from "../../../modules/Common/RestoreModal";
import "./header.scss";
import "./index.css";
import { changeModal, getCount, getNotifications } from "../../../actions/notificationActions";
import { saveSignUp, popupoff, popup } from "../../../actions/signUpAction";
import trolly from "../../../images/new-design/cart-new.svg";
import WOW from "../../../js/wow";
import fetchData, { checkValue, NodeURL, RootdomainURL } from "../../../services";
import NotificationModal from "./notificationModal";
import OneNotificationModal from "./oneNotificationModal";
import SubdomainRemind from "../SubdomainRemidModal";
import MobileModal from './MobileModal';
import CONFIG from "../../../config"
import { Modal as ReactstrapModal, ModalBody } from 'reactstrap';
import {Dropdown as dropDown,  MenuItem } from '@trendmicro/react-dropdown';
import SubscriptionList from "../../Subscriptions/SubscriptionList";
// import '../DocumentAccessModal/index.css';

let validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const url = "https://calendly.com/d/dpp-jhs-cxx/cloudoc-system-demo";

const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '00a2ff',
    textColor: '4d5055'
}

const prefill = {}

const utm = {
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: ''
}

const Header = (props) => {
    const dispatch = useDispatch(),
        history = useHistory(),
        authCheck = useSelector(state => state.authentication.isAuthenticated),
        cartCount = useSelector(state => state.UIReducer.cartCount),
        settingsData = useSelector(state => state.subscriptionData.settingData),
        companyData = useSelector(state => state.UIReducer.userDetails),
        myProfile = useSelector(state => state.myProfile.addViewData),
        dropdownOpen = useSelector(state => state.UIReducer.dropdownOpen),
        showRestoreModal = useSelector(state => state.UIReducer.showRestoreModal),
        restoreOption = useSelector(state => state.UIReducer.restoreOption),
        pop_show_hide = useSelector(state => state.UIReducer.pop_show_hide),
        active_subs = useSelector(state => state.userLoginData.active_subs),
        Comp_details = useSelector(state => state.userLoginData.Comp_details),
        dismiss_subs = useSelector(state => state.userLoginData.dismiss_subs),
        offline_cart_data_count = useSelector(state => state.cartData.offline_cart_data_count),
        dismiss_comp_details = useSelector(state => state.userLoginData.dismiss_comp_details),
        formData = useSelector(state => state.userLoginData.formData),
        submitButton = useSelector(state => state.button.pending),
        activeTab = useSelector(state => state.userLoginData.activeTab),
        showLoginModal = useSelector(state => state.userLoginData.showLoginModal),
        showForgotModal = useSelector(state => state.userLoginData.showForgotModal),
        showOptModal = useSelector(state => state.userLoginData.showOptModal),
        showNewPwdModal = useSelector(state => state.userLoginData.showNewPwdModal),
        forgotUser = useSelector(state => state.userLoginData.forgotUser),
        showMainModal = useSelector(state => state.userLoginData.showMainModal),
        path = useSelector(state => state.userLoginData.path),
        showError = useSelector(state => state.UIReducer.showError),
        pop_show = useSelector(state => state.cartData.pop_show),
        dismiss_msg = useSelector(state => state.cartData.dismiss_msg),
        userSubscribedPlan = useSelector(state => state.subscriptionData.userSubscribedPlan),
        docUpdateStatus = useSelector(state => state.userLoginData.docUpdateStatus),
        showNotification = useSelector(state => state.notificationData.showNotification),
        unReadCount = useSelector(state => state.notificationData.unread),
        showOneNotification = useSelector(state => state.notificationData.oneNotification),
        errorMsg = useSelector(state => state.UIReducer.errorMessage),
        companyDetails = useSelector(state => state.userLoginData.companyDetails),
        subdomainRemindModal = useSelector(state => state.myProfile.subdomainRemindModal),
        sub_categoryList = useSelector(state => state.subscriptionData.categoryList),
        // licenseStatus = useSelector(state => state.userLoginData.licenseStatus),
        // showMsg = useSelector(state => state.UIReducer.notifyModal),
        mobileModal = useSelector(state => state.UIReducer.mobileModal);
    // showLogOutModal = useSelector(state => state.UIReducer.showLogOutModal),
    // offline_cart = useSelector(state => state.cartData.offline_cart_dat),
    const reRef = useRef(null);
    const [state, setState] = useState({
        showError: false,
        tooltipOpen: false,
        showInfo: false
    });
    const [show, setShow] = useState(true);
    const [otpnum, setotp] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [otpmodel, setOtpModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPolicy, setIsOpenPolicy] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };
    
      const handleMouseEnter = () => {
        setIsOpen(true);
      };
    
      const handleMouseLeave = () => {
        setIsOpen(false);
      };
      const policyOver = ()=>{
        setIsOpenPolicy(true)
      }
      const policyNotOver = ()=>{
        setIsOpenPolicy(false)
      }

    useEffect(() => {
        new WOW().init();
        const connectionOptions = {
            'reconnection': true,
            'reconnectionDelay': 1000,
            'reconnectionDelayMax': 5000,
            "force new connection": true,
            reconnectionAttempts: "Infinity",
            timeout: 10000,
            forceNew: true,
            transports: ["websocket"]
        };
        const connectionOptions_live = {
            'reconnection': true,
            'reconnectionDelay': 1000,
            'reconnectionDelayMax': 5000,
            "force new connection": true,
            reconnectionAttempts: "Infinity",
            timeout: 10000,
            forceNew: true
        };
        let socket = '';
        if (String(NodeURL) === 'https://www.cloudoc.co.uk') {
            socket = io(NodeURL, connectionOptions_live);
        } else {
            socket = io(NodeURL, connectionOptions);
        };
        socket.emit('connection', {});
        socket.on('connection', () => { console.log(`Connected with back end`); });
        socket.on('login_success', data => {
            if (data && data.userId) {
                if (String(data.userId) === String(sessionStorage.getItem('login_user_id')) || String(data.userId) === String(sessionStorage.getItem('userId'))) {
                    getCompanyDetails();
                    dispatch(get_comp_details_keys());
                    dispatch(viewProfile());
                }
            }
        });

        if (props.location.search && props.location.search !== "") {
            let userDetails = props.location.search
                .substr(1)
                .split("&")
                .reduce(function (accumulator, currentValue) {
                    var pair = currentValue.split("=").map(function (value) {
                        return decodeURIComponent(value);
                    });
                    accumulator[pair[0]] = pair[1];
                    return accumulator;
                }, {});
            if (userDetails !== "") {
                dispatch(adminUserLogin(userDetails));
            }
        } else {
            let data = sessionStorage.getItem("usersessiontoken");
            if (data) {
                data = JSON.parse(data);
                let now = new Date();
                let expiration = new Date(data.timestamp);
                expiration.setMinutes(expiration.getMinutes() + 240);
                if (now.getTime() > expiration.getTime()) {
                    dispatch(logOut(props.history));
                }
            }
            if (sessionStorage.getItem("isAuthenticated") === "true") {
                dispatch(userLogin());
            }
        }
        dispatch(categoryList());
        dispatch(getSettingsData());
        if (sessionStorage.getItem('DISMIS_1')) dispatch(DISMIS_1());
        if (sessionStorage.getItem('DISMIS')) dispatch(DISMIS());

        let host = window.location.hostname;
        let hasSubdomain = host.split('.').length > 1 ? true : false;
        if (hasSubdomain) {
            let domainName = host.split('.')[0];
            if (domainName !== 'caredocument' || domainName !== 'cloudoc' || domainName !== 'www') {
                dispatch(getCompanyCredetials(domainName, props));
            }
        }

        return () => {
            dispatch(Clear_subs_Data());
            sessionStorage.removeItem("activeClass");
        }
    }, []);

    useEffect(() => {
        getCompanyDetails();
    }, [dispatch]);

    useEffect(() => {
        let pathname = window.location.pathname;
        if (companyDetails && companyDetails.subdomain && checkValue(companyDetails.subdomain)) {
            if (pathname === '/company/policy/documents' || pathname === '/company/policy/document/view') {
                let path = pathname.split('/').splice(2).join('/');
                props.history.push(`/${path}`)
            } else if (companyDetails && Object.keys(companyDetails).length > 0 && String(isAuthenticated) === 'false') {
                if (window.location && window.location.hash) {
                    props.history.push(`/login/${window.location.hash}`)
                } else {
                    props.history.push('/login')
                }
            }
        } else {
            if (companyDetails && Object.keys(companyDetails).length > 0 && String(isAuthenticated) === 'false') {
                props.history.push('/login')
            }
        }
    }, [companyDetails])


    let getCompanyDetails = () => {
        let getUserId = sessionStorage.getItem("userId");
        if (getUserId) {
            dispatch(getCartCount(getUserId));
            dispatch(get_comp_details_keys());
            dispatch(getCount(getUserId));
            dispatch(viewProfile());
            dispatch(listSubscription());
            dispatch(getUserDetails(getUserId));
        } else {
            let get_offline_cart = localStorage.getItem('offline_cart_subscription_details');
            if (get_offline_cart && String(get_offline_cart) !== String(null) && String(get_offline_cart) !== String(undefined) && String(get_offline_cart).length > 0) {
                let parsed_cart = JSON.parse(get_offline_cart);
                dispatch(IMPORT_FROM_LOCAL(parsed_cart));
            }
        }
    },
        subdoaminToggle = () => setState({ ...state, showInfo: !state.showInfo }),
        myProfilefun = (type) => {
            props.history.push("/profile");
            sessionStorage.setItem("profileType", type);
            dispatch(LogOutModalView(props));
            dispatch(setDropdownOpen(false));
        },
        company_settings = () => {
            props.history.push("/settings");
            dispatch(setDropdownOpen(false));
        },
        subscriptionList = () => {
            props.history.push("/subscriptions");
            dispatch(setDropdownOpen(false));
        },
        restore_modal_view = () => dispatch(restoreModalView()),
        onRestoreAllDocuments = () => dispatch(restoreDocumentToCompany(restoreOption, props)),
        logout_user = (e) => {
            e.preventDefault();
            dispatch(logOut(props.history));
        },
        redirect_to = e => {
            e.preventDefault();
            if (userSubscribedPlan && userSubscribedPlan.length > 0) {
                props.history.push({ pathname: '/subscriptions', state: { active_tab: '1' } })
            } else {
                props.history.push({ pathname: '/subscriptions', state: { active_tab: '2' } })
            }
        },
        dismiss = e => {
            e.preventDefault();
            dispatch(DISMIS());
            sessionStorage.setItem('DISMIS', true);
        },
        dismiss_subsfun = e => {
            e.preventDefault();
            dispatch(DISMIS_1());
            sessionStorage.setItem('DISMIS_1', true);
        },
        dismiss_msgfun = (e) => {
            e.preventDefault();
            dispatch(dismiss_msg_pop());
        },
        handleChange = (e, key) => {
            if (String(key) === 'remember') {
                formData[key] = e.target.checked;
            } else if (String(key) === 'email') {
                formData[key] = e.target.value ? String(e.target.value).toLowerCase() : '';
            } else {
                formData[key] = e.target.value ? e.target.value : '';
            }
            dispatch(addLoginData(formData));
        },
        mainToggle = () => {
            setOtpModal(false)
            setShow(false)
            dispatch(authModal('home'))
        },
        loginToggle = () => {
            dispatch(signInModal());
            dispatch(clearFormData());
        },
        forgotToggle = () => dispatch(forgotModal()),
        toggleTab = tab => (activeTab !== tab) && dispatch(setActiveTab(tab)),
        signupSubmitForm = async () => {
            if (formData && formData.firstname &&
                formData.lastname && formData.email &&
                formData.password && formData.companyName &&
                formData.confirmPassword && formData.personal_phone &&
                formData.subdomain
            ) {
                if (!validateEmail(formData.email)) {
                    dispatch(getErrorMsg("Valid Email is required"));
                } else if (String(formData.password) !== String(formData.confirmPassword)) {
                    dispatch(getErrorMsg("Password and Confirm password should be same"));
                } else {
                    let response = fetchData({
                        url: '/user/validate',
                        method: 'POST',
                        data: formData
                    })
                    response.then(async res => {
                        if (res.status === 1) {
                            const token = await reRef.current.executeAsync()
                            reRef.current.reset();
                            let response = fetchData({
                                url: '/recaptcha/api/siteverify',
                                method: 'POST',
                                data: { response: token },
                            });
                            response.then(res => {
                                if (res.success) {
                                    let response = fetchData({
                                        url: '/email/otp/verification',
                                        method: 'POST',
                                        data: { email: formData.email, name: formData.firstname + formData.lastname },
                                    });
                                    response.then(res => {
                                        if (res.status === 1) {
                                            dispatch(popupoff());
                                            setOtpModal(true)
                                            dispatch(popup("OTP has been sent to your registered mail successfully."));
                                            setotp(res.data)
                                            setShowButton(true);
                                        } else {
                                            dispatch(getErrorMsg("Something Went Wrong"));
                                        }
                                    })

                                } else {
                                    dispatch(getErrorMsg(res.response))
                                }
                            })
                        } else {
                            dispatch(getErrorMsg(res.response));
                        }
                    })
                }
            }
            else {
                dispatch(getErrorMsg("All fields are required"));
            }
        },
        loginSubmitForm = () => {
            if (formData && formData.email && String(formData.email).length > 0 && String(formData.email) !== String(undefined) && String(formData.email) !== String(null)) {
                if (formData && formData.password && String(formData.password).length > 0 && String(formData.password) !== String(undefined) && String(formData.password) !== String(null)) {
                    dispatch(userLogin(formData, props.history, props, path));
                } else {
                    dispatch(getErrorMsg("Password is required"));
                }
            } else {
                dispatch(getErrorMsg("Email is required"));
            }
        },
        handleSubmit = (e) => {
            if (formData.email && String(formData.email).length > 0) {
                if (validateEmail(formData.email)) {
                    dispatch(forgotPassword(formData.email, props.history, props));
                } else {
                    dispatch(getErrorMsg("Invalid email"))
                }

            } else {
                dispatch(getErrorMsg("Email is required"))
            }
        },
        setActiveClass = (data) => sessionStorage.setItem("activeClass", data),
        getCodeBoxElement = (index) => document.getElementById('otp' + index),
        jumpto = (event, index) => {
            const eventCode = event.which || event.keyCode;
            if (getCodeBoxElement(index).value.length === 1) {
                if (index !== 4) {
                    getCodeBoxElement(index + 1).focus();
                } else {
                    // getCodeBoxElement(index).blur();
                }
            }
            if (eventCode === 8 && index !== 1) {
                getCodeBoxElement(index - 1).focus();
            }
        },

        //   onFocusEvent = (index) => {
        //     for (let item = 1; item < index; item++) {
        //       const currentElement = getCodeBoxElement(item);
        //       if (!currentElement.value) {
        //           currentElement.focus();
        //           break;
        //       }
        //     }
        //   }

        submitOtp = () => {
            if (formData && formData.otp1 && formData.otp1 !== ""
                && formData.otp2 && formData.otp2 !== ""
                && formData.otp3 && formData.otp3 !== ""
                && formData.otp4 && formData.otp4 !== "") {

                dispatch(otpSubmit(formData, forgotUser));
            } else {
                dispatch(getErrorMsg('Enter correct otp'));
            }
        },
        resendotp = () => {
            let response = fetchData({
                url: '/email/otp/verification',
                method: 'POST',
                data: { email: formData.email, name: formData.firstname + formData.lastname },
            });
            response.then(res => {
                if (res.status === 1) {
                    dispatch(popup("OTP has been successfully resent to your registered email"));
                    setShowButton(true);
                    setotp(res.data)
                }
            })
        },
        submitemailOtp = () => {
            if (formData && formData.otp1 && formData.otp1 !== ""
                && formData.otp2 && formData.otp2 !== ""
                && formData.otp3 && formData.otp3 !== ""
                && formData.otp4 && formData.otp4 !== "") {
                let response = fetchData({
                    url: '/otp/time/verification',
                    method: 'POST',
                    data: { data: otpnum, votp: formData.otp1 + formData.otp2 + formData.otp3 + formData.otp4 },
                });
                response.then(res => {
                    if (res.status === 1) {
                        dispatch(saveSignUp(formData, props));
                    } else {
                        dispatch(getErrorMsg(res.response));
                    }
                })
            } else {
                dispatch(getErrorMsg('Enter correct otp'));
            }
        },

        // gotoDocuments = () => {
        //     let token = sessionStorage.getItem('token'),
        //         login_user_id = sessionStorage.getItem('login_user_id'),
        //         user_permission = sessionStorage.getItem('user_permission'),
        //         userId = sessionStorage.getItem('userId'),
        //         sideBarSiteExpanded = sessionStorage.getItem('sideBarSiteExpanded'),
        //         companyUserId = sessionStorage.getItem('companyUserId'),
        //         usersessiontoken = sessionStorage.getItem('usersessiontoken'),
        //         userRole = sessionStorage.getItem('userRole'),
        //         isAuthenticated = sessionStorage.getItem('isAuthenticated'),
        //         userName = sessionStorage.getItem('userName'),
        //         subDomain = myProfile && myProfile.companyDetails && myProfile.companyDetails.subdomain ? myProfile.companyDetails.subdomain : ''
        //     if (subDomain && subDomain !== '' && subDomain !== undefined) {
        //         if (RootdomainURL.includes('localhost')) {
        //             window.location.href = `http://${subDomain}.${RootdomainURL}/company/dashboard`;
        //         } else {
        //             window.location.href = `https://${subDomain}.${RootdomainURL}/company/dashboard`;
        //         }
        //     } else {
        //         props.history.push('/dashboard');
        //     }

        //     sessionStorage.setItem('token', token);
        //     sessionStorage.setItem('login_user_id', login_user_id);
        //     sessionStorage.setItem('user_permission', user_permission);
        //     sessionStorage.setItem('userId', userId);
        //     sessionStorage.setItem('sideBarSiteExpanded', sideBarSiteExpanded);
        //     sessionStorage.setItem('companyUserId', companyUserId);
        //     sessionStorage.setItem('usersessiontoken', usersessiontoken);
        //     sessionStorage.setItem('userRole', userRole);
        //     sessionStorage.setItem('isAuthenticated', isAuthenticated);
        //     sessionStorage.setItem('userName', userName);
        //     sessionStorage.setItem('subDomain', subDomain);
        // },

        // otpToggle = () => dispatch(otpModal()),

        // newPwdToggle = () => dispatch(newPwdModal()),

        // otpChange = (e) => {
        //     let newvalue = e.target.value.replace(/[^0-9]/g, '');
        //     if (newvalue && newvalue.length > 0) {
        //         setState({ ...state, otpValue: newvalue })
        //     }
        // },

        // toggle = () => dispatch(setDropdownOpen()),

        // tooltipToggle = () => setState({ ...state, tooltipOpen: !state.tooltipOpen }),

        submitNewPassword = () => dispatch(createNewPassword(formData, forgotUser)),
        openPopup = () => openPopupWidget({ url, prefill, pageSettings, utm }),
        notification = (key) => {
            let getUserId = sessionStorage.getItem("userId");
            dispatch(getNotifications(getUserId, 10));
            key === "home" ? dispatch(changeModal("home")) : dispatch(changeModal("document"));
        },
        profileToggle = () => dispatch(setDropdownOpen(!dropdownOpen)),
        gotoPublicDocument = () => {
            props.history.push('/publishedpolicies');
            dispatch(setDropdownOpen(false));
        }

    // if (licenseStatus && checkValue(licenseStatus) && licenseStatus === 'expired') {
    //     if (window.location.pathname === '/company/subscriptions') {
    //         dispatch(subExpiryNotifyToggle(false))
    //     } else {
    //         dispatch(subExpiryNotifyToggle(true))
    //     }
    // }

    let activeClass = sessionStorage.getItem("activeClass") ? sessionStorage.getItem("activeClass") : null;
    let user_permission = sessionStorage.getItem('user_permission') ? JSON.parse(sessionStorage.getItem('user_permission')) : [];
    if (showError === true) {
        setTimeout(() => {
            dispatch(closeErrorMsg());
        }, 2000)
    }
    let displayName =
        myProfile.firstname && myProfile.lastname
            ? myProfile.firstname.charAt(0) + myProfile.lastname.charAt(0)
            : myProfile.username
                ? myProfile.username.charAt(0) + myProfile.username.charAt(myProfile.username.length - 1)
                : myProfile.companyName
                    ? myProfile.companyName.charAt(0) + myProfile.companyName.charAt(myProfile.companyName.length - 1)
                    : null;
    let longName =
        myProfile.firstname && myProfile.lastname
            ? myProfile.firstname + " " + myProfile.lastname
            : myProfile.username
                ? myProfile.username
                : myProfile.companyName
                    ? myProfile.companyName
                    : null;
    let isAuthenticated = sessionStorage.getItem("isAuthenticated") ? sessionStorage.getItem("isAuthenticated") : authCheck;

    const excludedPaths = [
        "/documents", "/dashboard", "/users", "/subscriptions", "/users/add",
        "/users/edit", "/users/activity", "/saved-documents", "/create-new-documents",
        "/users/deleted-list", "/subscription/pay", "/documents/compare",
        "/documents/editor", "/index", "/documents/recent-updates", "/documents/need-review",
        "/documents/uptodate", "/settings", "/document/management/renewal", "/invoice",
        "/notifications", "/history", "/groups", "/group/add", "/group/edit", "/group/view",
        "/policies", "/policies/edit", "/policies/create", "/policies/multi/create",
        "/users/view", "/policy-documents", "/employees", "/employee/add", "/employee/edit",
        "/employee/view", "/deleted/employees", "/login", "/forgotpassword", "/employee/login",
        "/employee/register", "/verifyotp", "/resetpassword", "/myfiles", "/myfiles/editor",
        "/myfiles/details", "/policy/documents", "/policy/document/view", "/publishedpolicies",
        "/login/", "/deleted-documents","/myDocuments", "/myDocuments/editor","/view/Document",
        "/myDocuments/details",
    ];

const policyChange = (value) => {
    props.history.push({ pathname: '/policies-procedures', state: { category: value } })
    setIsOpenPolicy(false)
   
}
    if (props.location &&
        !excludedPaths.includes(props.location.pathname) &&
        !props.location.pathname.startsWith('/reference/') && !(props.location.pathname).startsWith("/document/sign") && !(props.location.pathname).startsWith("/view/Document/")
    ) {
        let pathname = "/";
        const exceptThisSymbols = ["e", "E", "+", "-", "."];
        return (
           <>
              <section className={String(props.location.pathname) === String('/') ? 'header header-bg' : 'header divider header-bg'}>
                 <div className="container">
                    <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 head-left">
                       <div className="site-logo">
                          {String(props.location.pathname) === '/policies-procedures' ||
                          String(props.location.pathname).includes('/subscription-detail') ||
                          String(props.location.pathname) === '/signin' ||
                          String(props.location.pathname) === '/signup' ||
                          String(props.location.pathname) === '/cart' ||
                          String(props.location.pathname) === '/order/history' ||
                          String(props.location.pathname) === '/checkout' ||
                          String(props.location.pathname) === '/faq' ||
                          String(props.location.pathname) === '/profile' ||
                          String(props.location.pathname) === '/pages/privacy-policy' ||
                          String(props.location.pathname) === '/contact-us' ||
                          String(props.location.pathname) === '/features' ||
                          String(props.location.pathname) === '/knowledge' ||
                          String(props.location.pathname) === '/pages/disclaimer' ||
                          String(props.location.pathname) === '/pages/user-licence-agreement' ||
                          String(props.location.pathname) === '/pages/disclaimer' ||
                          String(props.location.pathname) === '/forgotpassword' ||
                          String(props.location.pathname) === '/pages/terms-and-conditions' ||
                          String(props.location.pathname).includes('/pages/') ||
                          String(props.location.pathname).includes('/404') ||
                          String(props.location.pathname).includes('/resetpassword/') ? (
                             <Link to={pathname} onClick={() => sessionStorage.removeItem('activeClass')}>
                                <img src={logo} alt="logo2" className="home-logo" />
                             </Link>
                          ) : (
                             <Link to={pathname} onClick={() => sessionStorage.removeItem('activeClass')}>
                                <img src={logo} alt="logo2" className="home-logo" />
                             </Link>
                          )}
                       </div>
                    </div>
                    {props.location.pathname === '/profile' && sessionStorage.getItem('userRole') !== 'user' ? (
                       pop_show && !dismiss_msg ? (
                          <div className="top-pop">
                             <span>
                                Thank you for purchasing your documents, your documents are now available, you can access them by clicking the Documents menu,
                                in order to customise them, please click here to complete you business information as you would like it to appear on the
                                documents.{' '}
                             </span>
                             <span>
                                <button onClick={(e) => dismiss_msgfun(e)}>Dismiss</button>
                             </span>
                          </div>
                       ) : null
                    ) : null}
                    <div className="col-lg-10 col-md-8 col-sm-12 col-xs-12 head-right">
                       <div className="not-cart">
                          <ul>
                             <li className="nav-item">
                                <Link className={'nav-link'} to="/cart">
                                   <div className="basket-icon">
                                      <img src={trolly} alt="cart" />
                                   </div>
                                   <p className="bell-count">0</p>
                                </Link>
                             </li>
                             {/* <li className="nav-item">
                                        <IconButton aria-label="notification" onClick={() => this.notification("home")}>
                                            <Badge className="home-badge">
                                            </Badge>
                                            <NotificationsIcon style={{ color: grey[900] }} />
                                        </IconButton>
                                    </li> */}
                          </ul>
                       </div>
                       <nav className="navbar navbar-expand-lg navbar-light bg-light">
                          <button
                             className="navbar-toggler"
                             // type="button"
                             // data-toggle="collapse"
                             // data-target="#navbarNavDropdown"
                             // aria-controls="navbarNavDropdown"
                             // aria-expanded="false"
                             // aria-label="Toggle navigation"
                             onClick={() => dispatch(mobileModalFunc())}
                          >
                             <span className="navbar-toggler-icon"></span>
                          </button>
                          <div className="collapse navbar-collapse" id="navbarNavDropdown" style={{ display: !show && showMainModal ? 'none' : '' }}>
                             <ul className="navbar-nav">
                                {String(isAuthenticated) === String('true') ? (
                                   <li className="nav-item">
                                      <Link className="nav-link" style={{ color: '#f77d24' }} to="/documents">
                                         Document Library
                                      </Link>
                                   </li>
                                ) : null}
                                {String(isAuthenticated) === String('true') ? (
                                   <li className="nav-item">
                                      {props.location.pathname.includes('/policies-procedures') ? (
                                         <Link className={activeClass === '1' ? 'nav-link active-navItem' : 'nav-link'} to="/policies-procedures">
                                            Policies & Procedures{' '}
                                         </Link>
                                      ) : (
                                         <Dropdown isOpen={isOpenPolicy} onMouseOver={policyOver} onMouseLeave={policyNotOver} className="policyDropdown-main">
                                            <DropdownToggle caret className="policyDropdown-container">
                                               Policies & Procedures
                                            </DropdownToggle>
                                            <DropdownMenu>
                                               {sub_categoryList &&
                                                  sub_categoryList.length > 0 &&
                                                  sub_categoryList.map((item, i) => (
                                                     <MenuItem eventKey={i}>
                                                        <span className="policyDropdownmenu" onClick={() => policyChange(item.slug)}>
                                                           {item?.name === 'Home Care' ? 'Domiciliary Care': item?.name}
                                                        </span>
                                                     </MenuItem>
                                                  ))}
                                            </DropdownMenu>
                                         </Dropdown>
                                      )}
                                   </li>
                                ) : (
                                   <li className="nav-item" onClick={() => setActiveClass(1)}>
                                      {/* <Link className={activeClass === "1" ? "nav-link active-navItem" : "nav-link"} to="/policies-procedures">Policies & Procedures </Link> */}
                                      {props.location.pathname.includes('/policies-procedures') ? (
                                         <Link className={activeClass === '1' ? 'nav-link active-navItem' : 'nav-link'} to="/policies-procedures">
                                            Policies & Procedures{' '}
                                         </Link>
                                      ) : (
                                         <Dropdown
                                            isOpen={isOpenPolicy}
                                            onMouseOver={policyOver}
                                            //  onClick={() => props.history.push('/policies-procedures')}
                                            onMouseLeave={policyNotOver}
                                            className="policyDropdown-main"
                                         >
                                            <DropdownToggle caret className="policyDropdown-container">
                                               Policies & Procedures
                                            </DropdownToggle>
                                            <DropdownMenu>
                                               {sub_categoryList &&
                                                  sub_categoryList.length > 0 &&
                                                  sub_categoryList.map((item, i) => (
                                                     <MenuItem eventKey={i}>
                                                        <span className="policyDropdownmenu" onClick={() => policyChange(item.slug)}>
                                                           {item?.name === 'Home Care' ? 'Domiciliary Care': item.name}
                                                        </span>
                                                     </MenuItem>
                                                  ))}
                                            </DropdownMenu>
                                         </Dropdown>
                                      )}
                                   </li>
                                )}
                                {String(isAuthenticated) === String('true') ? null : (
                                   <li className="nav-item" onClick={() => setActiveClass(2)}>
                                      <Link className={activeClass === '2' ? 'nav-link active-navItem' : 'nav-link'} to="/features">
                                         System Features{' '}
                                      </Link>
                                   </li>
                                )}
                                {String(isAuthenticated) === String('true') ? null : (
                                   <li className="nav-item">
                                      <a className="nav-link" target="_self" href={settingsData.blog_url}>
                                         Blog
                                      </a>
                                   </li>
                                )}
                                <Dropdown isOpen={isOpen} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                   <DropdownToggle caret>Help Centre</DropdownToggle>
                                   <DropdownMenu>
                                      <Link to="/knowledge" className="dropdown-item">
                                         Resources
                                      </Link>
                                      <Link to="/faq" className="dropdown-item">
                                         FAQs
                                      </Link>
                                      <Link to="/systemdocumentation" className="dropdown-item">
                                         System Documentation
                                      </Link>
                                   </DropdownMenu>
                                </Dropdown>
                                {/* {String(isAuthenticated) === String("true")
                                            ? <li className="nav-item">
                                                <Link className="nav-link" onClick={() => props.history.push('/knowledge')} to="/knowledge">Help Centre</Link>
                                            </li> : <li className="nav-item" onClick={() => setActiveClass(3)}>
                                                <Link className={activeClass === "3" ? "nav-link active-navItem" : "nav-link"} to="/knowledge">Help Centre</Link>
                                            </li>} */}
                                {String(isAuthenticated) === String('true') ? null : (
                                   <li className="nav-item" onClick={() => setActiveClass(4)}>
                                      <Link className={activeClass === '4' ? 'nav-link active-navItem' : 'nav-link'} to="/contact-us">
                                         Contact Us{' '}
                                      </Link>
                                   </li>
                                )}

                                {/* {String(isAuthenticated) === String("true")
                                            ? null : <li className="nav-item" onClick={() => setActiveClass(5)}>
                                                <Link className={activeClass === "5" ? "nav-link active-navItem" : "nav-link"} to="/faq">Resources FAQs</Link>
                                            </li>} */}
                                <li className="nav-item get-a-demo" onClick={() => setActiveClass(10)}>
                                   <span
                                      className={activeClass === '10' ? 'nav-link active-navItem' : 'nav-link'}
                                      onClick={openPopup}
                                      id={String(isAuthenticated) === String(true) ? 'buy-pol-authenticated' : 'buy-pol'}
                                   >
                                      Get a Demo{' '}
                                   </span>
                                </li>
                                {String(isAuthenticated) === String(true) ? (
                                   <li className="nav-item dropdown site-dropdown">
                                      <a
                                         className="nav-link dropdown-toggle my-acc-lnk"
                                         href="#"
                                         id="navbarDropdownMenuLink"
                                         data-toggle="dropdown"
                                         aria-haspopup="true"
                                         aria-expanded="false"
                                      >
                                         My Account
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                         <a className="dropdown-item" onClick={() => myProfilefun('myprofile')} href="#">
                                            My account
                                         </a>
                                         <a className="dropdown-item" onClick={() => props.history.push('/dashboard')} href="#">
                                            Dashboard
                                         </a>
                                         <a className="dropdown-item" onClick={() => props.history.push('/order/history')} href="#">
                                            Order history
                                         </a>
                                         <a className="dropdown-item log-out" href="#" onClick={(e) => logout_user(e)}>
                                            Logout
                                         </a>
                                      </div>
                                   </li>
                                ) : null}
                                {String(isAuthenticated) !== String(true) && (
                                   <li className="nav-item demo-but">
                                      <Link className="nav-link" onClick={mainToggle}>
                                         Sign In
                                      </Link>
                                   </li>
                                )}
                                {/* <li>
                                                <div className="mobile-num"> <span><FontAwesomeIcon icon={faPhoneAlt} /></span><span className="get-num"><p>Get a question? Call Us</p><a href="tel:0330 808 0050">{settingsData && settingsData.phone && settingsData.phone !== '' && settingsData.phone !== undefined ? (settingsData.phone).slice(0, 4) + ' ' + (settingsData.phone).slice(4, 7) + ' ' + (settingsData.phone).slice(7) : '0330 808 0050'}</a></span></div>
                                            </li> */}
                                {String(isAuthenticated) === String(true) ? (
                                   <li className="nav-item nav-basket">
                                      <Link className="nav-link" to="/cart">
                                         {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
                                         <div className="basket-icon">
                                            <img src={trolly} alt="cart" />
                                         </div>
                                         <p className={cartCount > 0 ? 'bell' : ''}>{cartCount > 0 ? cartCount : ''}</p>
                                      </Link>
                                   </li>
                                ) : (
                                   <li className="nav-item  basket-svg" onClick={() => setActiveClass(5)}>
                                      <Link className={activeClass === '5' ? 'nav-link active-navItem' : 'nav-link'} to="/cart">
                                         {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
                                         <div className={activeClass === '5' ? 'basket-icon active-basket' : 'basket-icon'}>
                                            <img src={trolly} alt="cart" />
                                         </div>
                                         <p className={offline_cart_data_count > 0 ? 'bell' : ''}>
                                            {offline_cart_data_count > 0 ? offline_cart_data_count : ''}
                                         </p>
                                      </Link>
                                   </li>
                                )}
                                {String(isAuthenticated) === String(true) ? (
                                   <li className="nav-item bell-icon">
                                      <IconButton aria-label="notification" onClick={() => notification('home')}>
                                         {unReadCount > 0 ? <Badge badgeContent={unReadCount} className="home-badge"></Badge> : null}
                                         <NotificationsIcon style={{ color: grey[900] }} />
                                      </IconButton>
                                   </li>
                                ) : null}
                             </ul>
                          </div>
                       </nav>
                       {/* <div className="mobile-num"> <span><FontAwesomeIcon icon={faPhoneAlt} /></span><span className="get-num"><p>Get a question? Call Us</p><a href="tel:0203 318 5781">0203 318 5781</a></span></div> */}
                    </div>
                 </div>
                 {showNotification ? <NotificationModal history={props.history} /> : null}
                 {showOneNotification ? <OneNotificationModal /> : null}
                 {showRestoreModal ? <RestoreModal history={props.history} restoreFunction={onRestoreAllDocuments} /> : null}
                 {pop_show_hide ? <BottomModal history={props.history} /> : null}
                 {showMainModal ? (
                    <>
                       <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          className="login-modal"
                          open={showMainModal}
                          onClose={!otpmodel ? mainToggle : () => {}}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                             timeout: 500,
                          }}
                          toggle={!otpmodel ? mainToggle : () => {}}
                       >
                          <Fade in={showMainModal}>
                             <div className="auth-content">
                                {showLoginModal ? (
                                   <>
                                      <div className="modal-header">
                                         <Nav tabs className="login_form_header">
                                            <NavItem>
                                               <NavLink
                                                  className={classnames({ active: activeTab === '1' })}
                                                  onClick={() => {
                                                     toggleTab('1');
                                                  }}
                                               >
                                                  Sign In
                                               </NavLink>
                                            </NavItem>
                                            <NavItem>
                                               <NavLink
                                                  className={classnames({ active: activeTab === '2' })}
                                                  onClick={() => {
                                                     toggleTab('2');
                                                  }}
                                               >
                                                  Sign Up
                                               </NavLink>
                                            </NavItem>
                                         </Nav>
                                      </div>
                                      <div className="modal-body">
                                         <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                               <Row>
                                                  <Col sm="12">
                                                     <AvForm onSubmit={loginSubmitForm}>
                                                        <div className="form_input">
                                                           <AvField
                                                              name="email"
                                                              placeholder="Email Address"
                                                              value={formData && formData.email ? formData.email : ''}
                                                              id="login-email"
                                                              onChange={(e) => handleChange(e, 'email')}
                                                              type="text"
                                                              autoFocus
                                                           />
                                                           <span className="field_icon">
                                                              <img src={userIcon} alt="user-email" />
                                                           </span>
                                                        </div>
                                                        <div className="form_input">
                                                           <AvField
                                                              name="password"
                                                              type="password"
                                                              autoComplete="off"
                                                              placeholder="Password"
                                                              id="login-password"
                                                              value={formData && formData.password ? formData.password : ''}
                                                              onChange={(e) => handleChange(e, 'password')}
                                                           />
                                                           <span className="field_icon">
                                                              <img src={passwordIcon} alt="user-password" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <Row className="rem-forget">
                                                              <Col md="6">
                                                                 <div>
                                                                    <AvGroup check>
                                                                       <AvInput
                                                                          type="checkbox"
                                                                          name="remember"
                                                                          className="agree-check"
                                                                          onChange={(e) => handleChange(e, 'remember')}
                                                                       />
                                                                       <span className="checkmarker"></span>
                                                                       <Label check for="checkItOut">
                                                                          Remember Me
                                                                       </Label>
                                                                    </AvGroup>
                                                                 </div>
                                                              </Col>
                                                              <Col md="6">
                                                                 <div id="forgot_pwd" onClick={() => forgotToggle()}>
                                                                    Forgot Password?
                                                                 </div>
                                                              </Col>
                                                           </Row>
                                                        </div>
                                                        <div className="submit-anc">
                                                           {submitButton ? (
                                                              <Button>Sign in</Button>
                                                           ) : (
                                                              <Button style={{ cursor: 'wait' }} className="btn-rounded" disabled={true} color="warning">
                                                                 Processing...
                                                              </Button>
                                                           )}
                                                        </div>
                                                        <div className="already-acc">
                                                           <div>
                                                              Don't have an account?
                                                              <span className="movetosignup" onClick={() => toggleTab('2')}>
                                                                 Sign Up
                                                              </span>
                                                           </div>
                                                        </div>
                                                     </AvForm>
                                                  </Col>
                                               </Row>
                                            </TabPane>
                                            <TabPane tabId="2" className="sign-up-page">
                                               <Row>
                                                  <Col sm="12">
                                                     <AvForm
                                                        onSubmit={(e, errors, values) => {
                                                           e.preventDefault();
                                                           if (errors.length === 0) {
                                                              signupSubmitForm();
                                                           }
                                                        }}
                                                     >
                                                        <div>
                                                           <AvField
                                                              name="firstname"
                                                              id="first_name"
                                                              placeholder="First Name"
                                                              value={formData && formData.firstname ? formData.firstname : ''}
                                                              onChange={(e) => handleChange(e, 'firstname')}
                                                              type="text"
                                                              autoFocus
                                                           />
                                                           <span className="s-icons">
                                                              <img src={name} alt="firstname" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <AvField
                                                              name="lastname"
                                                              placeholder="Last Name"
                                                              value={formData && formData.lastname ? formData.lastname : ''}
                                                              onChange={(e) => handleChange(e, 'lastname')}
                                                              type="text"
                                                           />
                                                           <span className="s-icons">
                                                              <img src={name} alt="lastname" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <AvField
                                                              name="email"
                                                              placeholder="Email Address"
                                                              value={formData && formData.email ? formData.email : ''}
                                                              id="signup-email"
                                                              onChange={(e) => handleChange(e, 'email')}
                                                              type="email"
                                                           />
                                                           <span className="s-icons">
                                                              <img src={userIcon} alt="email" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <AvField
                                                              name="personal_phone"
                                                              placeholder="Phone Number"
                                                              value={formData && formData.personal_phone ? formData.personal_phone : ''}
                                                              onChange={(e) => handleChange(e, 'personal_phone')}
                                                              type="number"
                                                              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                              validate={{ required: { value: true }, minLength: { value: 10 }, maxLength: { value: 12 } }}
                                                           />
                                                           <span className="s-icons">
                                                              <img src={company} alt="phonenumber" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <AvField
                                                              name="companyName"
                                                              placeholder="Company Name"
                                                              value={formData && formData.companyName ? formData.companyName : ''}
                                                              onChange={(e) => handleChange(e, 'companyName')}
                                                              type="text"
                                                           />
                                                           <span className="s-icons">
                                                              <img src={company} alt="companyname" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <AvField
                                                              name="subdomain"
                                                              placeholder="Company domain name"
                                                              value={formData && formData.subdomain ? formData.subdomain : ''}
                                                              id="signup-subdomain"
                                                              onChange={(e) => handleChange(e, 'subdomain')}
                                                              type="text"
                                                              validate={{
                                                                 required: { value: false },
                                                                 pattern: { value: '^[a-zA-Z0-9]+$' },
                                                              }}
                                                           />
                                                           <span className="info-sec" id="subdomain-info">
                                                              <span className="info-icon">
                                                                 <img src={process.env.PUBLIC_URL + '/assets/images/info-g.svg'} alt="info" />
                                                              </span>
                                                              <Tooltip placement="top" isOpen={state.showInfo} target="subdomain-info" toggle={subdoaminToggle}>
                                                                 Choose a subdomain for your company/organisation, this could be the full name or a shorter
                                                                 memorable version of your company/organisation name
                                                              </Tooltip>
                                                           </span>
                                                           <span className="domain-ph">{RootdomainURL}</span>
                                                        </div>

                                                        <div>
                                                           <AvField
                                                              name="password"
                                                              type="password"
                                                              autoComplete="off"
                                                              placeholder="Password"
                                                              id="signup-password"
                                                              value={formData && formData.password ? formData.password : ''}
                                                              onChange={(e) => handleChange(e, 'password')}
                                                           />
                                                           <span className="s-icons">
                                                              <img src={passwordIcon} alt="password" />
                                                           </span>
                                                        </div>
                                                        <div>
                                                           <AvField
                                                              name="confirmpassword"
                                                              type="password"
                                                              autoComplete="off"
                                                              placeholder="Confirm Password"
                                                              value={formData && formData.confirmPassword ? formData.confirmPassword : ''}
                                                              onChange={(e) => handleChange(e, 'confirmPassword')}
                                                           />
                                                           <span className="s-icons">
                                                              <img src={passwordIcon} alt="confirmpassword" />
                                                           </span>
                                                        </div>
                                                        <ReCAPTCHA sitekey={CONFIG.RECAPTCHA_SITE_KEY} size="invisible" ref={reRef}>
                                                           {' '}
                                                        </ReCAPTCHA>
                                                        <div className="submit-anc">
                                                           {submitButton ? (
                                                              <Button color="primary">Sign up</Button>
                                                           ) : (
                                                              <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                                                 Processing...
                                                              </Button>
                                                           )}
                                                        </div>
                                                        <div className="already-acc">
                                                           <div>
                                                              Already have an account?{' '}
                                                              <span
                                                                 onClick={() => {
                                                                    toggleTab('1');
                                                                 }}
                                                                 className="sign-in-sec"
                                                              >
                                                                 Sign In
                                                              </span>{' '}
                                                           </div>
                                                        </div>
                                                     </AvForm>
                                                  </Col>
                                               </Row>
                                            </TabPane>
                                         </TabContent>
                                      </div>
                                   </>
                                ) : showForgotModal ? (
                                   <>
                                      <div className="modal-body forgot_modal">
                                         <AvForm onSubmit={handleSubmit}>
                                            <h4>Forgot Password</h4>
                                            <div className="form_input">
                                               <AvField
                                                  type="text"
                                                  name="email"
                                                  id="forgot-email"
                                                  placeholder="Enter Email Address"
                                                  onChange={(e) => handleChange(e, 'email')}
                                                  value={formData && formData.email ? formData.email : ''}
                                                  autoFocus
                                               />
                                               <span className="field_icon">
                                                  <img src={userIcon} alt="user-email" />
                                               </span>
                                            </div>
                                            <p>An OTP will be sent to your registered email address</p>
                                            <div className="for-submit-btn">
                                               {submitButton ? (
                                                  <Button color="primary">Next</Button>
                                               ) : (
                                                  <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                                     Processing...
                                                  </Button>
                                               )}
                                            </div>
                                         </AvForm>
                                         <div onClick={loginToggle} className="just-rem">
                                            Just remembered?<span>Sign In</span>
                                         </div>
                                      </div>
                                   </>
                                ) : showOptModal ? (
                                   <>
                                      <div className="modal-header otp-header">Enter OTP</div>
                                      <div className="modal-body enter-otp">
                                         <AvForm onSubmit={submitOtp}>
                                            <AvField
                                               type="text"
                                               name="otp1"
                                               id="otp1"
                                               maxLength="1"
                                               placeholder="*"
                                               onKeyUp={(e) => jumpto(e, 1)}
                                               onChange={(e) => handleChange(e, 'otp1')}
                                               autoFocus
                                            />
                                            <AvField
                                               type="text"
                                               name="otp2"
                                               id="otp2"
                                               maxLength="1"
                                               placeholder="*"
                                               onKeyUp={(e) => jumpto(e, 2)}
                                               onChange={(e) => handleChange(e, 'otp2')}
                                            />
                                            <AvField
                                               type="text"
                                               name="otp3"
                                               id="otp3"
                                               maxLength="1"
                                               placeholder="*"
                                               onKeyUp={(e) => jumpto(e, 3)}
                                               onChange={(e) => handleChange(e, 'otp3')}
                                            />
                                            <AvField
                                               type="text"
                                               name="otp4"
                                               id="otp4"
                                               maxLength="1"
                                               placeholder="*"
                                               onKeyUp={(e) => jumpto(e, 4)}
                                               onChange={(e) => handleChange(e, 'otp4')}
                                            />
                                            <div className="otp-but">
                                               {submitButton ? (
                                                  <Button color="primary">Next</Button>
                                               ) : (
                                                  <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                                     Processing...
                                                  </Button>
                                               )}
                                            </div>
                                         </AvForm>
                                      </div>
                                   </>
                                ) : showNewPwdModal ? (
                                   <>
                                      <div className="modal-header new-password-header">
                                         <span>Create New Password</span>
                                      </div>
                                      <div className="modal-body">
                                         <div className="new-password">
                                            <AvForm onSubmit={submitNewPassword}>
                                               <div className="new-password-field">
                                                  <AvField
                                                     type="password"
                                                     name="new_password"
                                                     placeholder="New Password"
                                                     value={formData && formData.new_password ? formData.new_password : ''}
                                                     onChange={(e) => handleChange(e, 'new_password')}
                                                     autoFocus
                                                  />
                                                  <span className="field_icon">
                                                     <img src={passwordIcon} alt="user-password" />
                                                  </span>
                                               </div>
                                               <div className="new-password-field">
                                                  <AvField
                                                     type="password"
                                                     name="new_con_password"
                                                     placeholder="Confirm Password"
                                                     value={formData && formData.new_con_password ? formData.new_con_password : ''}
                                                     onChange={(e) => handleChange(e, 'new_con_password')}
                                                  />
                                                  <span className="field_icon">
                                                     <img src={passwordIcon} alt="user-password" />
                                                  </span>
                                               </div>
                                               <div className="new-but">
                                                  {submitButton ? (
                                                     <Button color="primary">Submit</Button>
                                                  ) : (
                                                     <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                                        Processing...
                                                     </Button>
                                                  )}
                                               </div>
                                            </AvForm>
                                         </div>
                                      </div>
                                   </>
                                ) : null}
                                {showError ? <span className="error-modal">{errorMsg}</span> : ''}
                                {otpmodel ? (
                                   <>
                                      <div className="otp_modal_dialog">
                                         <div className="modal-header otp-header">
                                            <span>Email Verification</span>
                                            <span onClick={mainToggle} className="clsbut">
                                               X
                                            </span>
                                         </div>
                                         <p className="paraalign">
                                            We have sent you a message with a verification code. Check your inbox and enter the verification code below
                                         </p>
                                         <div className="modal-body enter-otp">
                                            <AvForm onSubmit={submitemailOtp}>
                                               <AvField
                                                  type="text"
                                                  name="otp1"
                                                  id="otp1"
                                                  maxLength="1"
                                                  placeholder="*"
                                                  onKeyUp={(e) => jumpto(e, 1)}
                                                  onChange={(e) => handleChange(e, 'otp1')}
                                                  autoFocus
                                               />
                                               <AvField
                                                  type="text"
                                                  name="otp2"
                                                  id="otp2"
                                                  maxLength="1"
                                                  placeholder="*"
                                                  onKeyUp={(e) => jumpto(e, 2)}
                                                  onChange={(e) => handleChange(e, 'otp2')}
                                               />
                                               <AvField
                                                  type="text"
                                                  name="otp3"
                                                  id="otp3"
                                                  maxLength="1"
                                                  placeholder="*"
                                                  onKeyUp={(e) => jumpto(e, 3)}
                                                  onChange={(e) => handleChange(e, 'otp3')}
                                               />
                                               <AvField
                                                  type="text"
                                                  name="otp4"
                                                  id="otp4"
                                                  maxLength="1"
                                                  placeholder="*"
                                                  onKeyUp={(e) => jumpto(e, 4)}
                                                  onChange={(e) => handleChange(e, 'otp4')}
                                               />
                                               <div className="Verification-but">
                                                  {submitButton ? (
                                                     <Button color="primary">Verify</Button>
                                                  ) : (
                                                     <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                                        Processing...
                                                     </Button>
                                                  )}
                                                  {
                                                     <Button color="secondary" disabled={showButton} onClick={resendotp}>
                                                        {!showButton ? (
                                                           'Resend Verification code'
                                                        ) : (
                                                           <ResendCounter
                                                              render={({ count }) => `Resend Verification code in - ${String(count).padStart(1, '0')} min`}
                                                              start={showButton}
                                                              onStop={setShowButton}
                                                              initialCount={5}
                                                           />
                                                        )}
                                                     </Button>
                                                  }
                                               </div>
                                            </AvForm>
                                         </div>
                                      </div>
                                   </>
                                ) : null}
                             </div>
                          </Fade>
                       </Modal>
                    </>
                 ) : null}
                 {subdomainRemindModal && <SubdomainRemind history={props.history} />}
                 {mobileModal ? <MobileModal history={props.history} /> : null}
              </section>
           </>
        );
    } else if (
        props.location.pathname === "/documents" ||
        props.location.pathname === "/dashboard" ||
        props.location.pathname === "/subscriptions" ||
        props.location.pathname === "/users" ||
        props.location.pathname === "/users/add" ||
        props.location.pathname === "/users/edit" ||
        props.location.pathname === "/users/activity" ||
        props.location.pathname === "/saved-documents" ||
        props.location.pathname === "/create-new-documents" ||
        props.location.pathname === "/users/deleted-list" ||
        props.location.pathname === "/subscription/pay" ||
        props.location.pathname === "/documents/compare" ||
        props.location.pathname === "/index" ||
        props.location.pathname === "/documents/recent-updates" ||
        props.location.pathname === "/documents/need-review" ||
        props.location.pathname === "/documents/uptodate" ||
        props.location.pathname === "/settings" ||
        props.location.pathname === "/document/management/renewal" ||
        props.location.pathname === "/invoice" ||
        props.location.pathname === "/notifications" ||
        props.location.pathname === '/history' ||
        props.location.pathname === '/groups' ||
        props.location.pathname === '/group/add' ||
        props.location.pathname === '/group/edit' ||
        props.location.pathname === '/group/view' ||
        props.location.pathname === '/policies' ||
        props.location.pathname === '/policies/edit' ||
        props.location.pathname === '/policies/create' ||
        props.location.pathname === '/users/view' ||
        props.location.pathname === '/policy-documents' ||
        props.location.pathname === '/employees' ||
        props.location.pathname === '/employee/add' ||
        props.location.pathname === '/employee/edit' ||
        props.location.pathname === '/employee/view' ||
        props.location.pathname === '/deleted/employees' ||
        props.location.pathname === '/myfiles' ||
        props.location.pathname === '/myfiles/details' ||
        props.location.pathname === '/myDocuments' ||
        props.location.pathname === '/myDocuments/details' ||
        props.location.pathname === '/publishedpolicies' ||
        props.location.pathname === '/deleted-documents' ||

        (props.location.pathname).includes('/reference/')

    ) {
        // let pathname =
        //     sessionStorage.getItem("userRole") === "user"
        //         ? "/dashboard"
        //         : "/dashboard";
        let pathExists = props.location.pathname !== "/documents/editor" ? true : false;
        return (
            <section className="header header-a">
                <div className="container">
                    {props.location.pathname === "/dashboard" && sessionStorage.getItem("userRole") !== "user" ?
                        (String(active_subs) === String(false)) && !dismiss_subs ?
                            <div className="top-pop">
                                <span>You do not currently have an active subscription, please subscribe </span>
                                <span  ><button onClick={e => redirect_to(e)} >Here</button></span>
                                <span  ><button onClick={e => dismiss_subsfun(e)} >Dismiss</button></span>
                            </div> : (Number(Comp_details) < Number(13)) && !dismiss_comp_details ?
                                <div className="top-pop">
                                    <span>Please complete your organisation details to customise your documents </span>
                                    <span><button onClick={() => myProfilefun("companydetail")} >Continue</button> </span>
                                    <span><button onClick={e => dismiss(e)} >Dismiss</button> </span>
                                </div>
                                : null
                        : null
                    }
                    <div className="header-sec header-fixed">
                        {pathExists && (
                            <div className="loging-sec head-common">
                                <IconButton aria-label="notification" onClick={() => props.history.push('/knowledge')}>
                                    <Badge>
                                        <HelpOutlineIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton aria-label="notification" onClick={() => notification()}>
                                    <Badge badgeContent={unReadCount} >
                                        <NotificationsNoneIcon />
                                    </Badge>
                                </IconButton>
                                <Dropdown isOpen={dropdownOpen} toggle={() => profileToggle()}>
                                    <DropdownToggle tag="div" className="profile-img" data-toggle={dropdownOpen}                                        >
                                        {displayName}
                                    </DropdownToggle>
                                    {dropdownOpen === true ? <DropdownMenu>
                                        <div className="inner_head_sec">
                                            <div className="inner_heads">
                                                <div className="cancel_headsec">
                                                    <span className="drop-profile">
                                                        <div className="profile-img">{displayName}</div>
                                                        <span className="drop-user">
                                                            <span>{longName}</span>
                                                            <span className="drop-email">
                                                                {myProfile.email ? myProfile.email : null}
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <a className="list_items" onClick={(e) => myProfilefun("myprofile")}>My Profile</a>
                                                    {String(active_subs) !== String(false) ?
                                                        (sessionStorage.getItem('userRole') === 'user') ? (
                                                            (user_permission.filter(e => String(e.name) === String('Settings') || String(e.name) === String('Settings')).length > 0) ?
                                                                <div className="navbar-dropdown" style={{ width: "100%" }}>
                                                                    <div className="nested navbar-item dropdown">
                                                                        <div className="dropdown-trigger">
                                                                            <a className="button list_items" aria-haspopup="true" aria-controls="dropdown-menu">
                                                                                {/* <span>{'<'}</span> */}
                                                                                <span className="icon-show">Settings</span>
                                                                            </a>
                                                                        </div>
                                                                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                                            <div className="dropdown-content">
                                                                                <a onClick={() => myProfilefun("companydetail")} className="dropdown-item list_items">Organisation Details</a>
                                                                                <a className="dropdown-item list_items" onClick={(e) => company_settings()}>Other Settings</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        ) : <div className="navbar-dropdown" style={{ width: "100%" }}>
                                                            <div className="nested navbar-item dropdown">
                                                                <div className="dropdown-trigger">
                                                                    <a className="button list_items" aria-haspopup="true" aria-controls="dropdown-menu">
                                                                        {/* <span>{'<'}</span> */}
                                                                        <span className="icon-show">Settings</span>
                                                                    </a>
                                                                </div>
                                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                                    <div className="dropdown-content">
                                                                        <a onClick={() => myProfilefun("companydetail")} className="dropdown-item list_items">Organisation Details</a>
                                                                        <a className="dropdown-item list_items" onClick={(e) => company_settings()}>System Settings</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {(sessionStorage.getItem('userRole') === 'user')
                                                        ? (user_permission.filter(e => String(e.name) === String('Subscriptions') || String(e.name) === String('Subscriptions')).length > 0)
                                                            ? <a className="list_items" onClick={(e) => subscriptionList()}>Subscription</a>
                                                            : null
                                                        : <a className="list_items" onClick={(e) => subscriptionList()}>Subscription</a>}
                                                    {/* <a className="list_items" onClick={(e) => subscriptionList()}>Subscription</a> */}
                                                    {(sessionStorage.getItem('userRole') === 'company' && myProfile && myProfile.settings && myProfile.settings.showPoliciesonSite && (myProfile.comboPackage && myProfile.comboPackage.status === 1 || myProfile.documentManagement && myProfile.documentManagement.status === 1 || myProfile.documentUpdate && myProfile.documentUpdate.status === 1 || myProfile.proPackage && myProfile.proPackage.status === 1))
                                                        ? <a className="list_items" onClick={(e) => gotoPublicDocument()}>Public Documents</a>
                                                        : null}
                                                    {String(active_subs) !== String(false) && docUpdateStatus && docUpdateStatus === 'active'
                                                        ? <a className="list_items" onClick={() => restore_modal_view()}>Restore All</a>
                                                        : null}
                                                    <a className="logout-menu" onClick={() => dispatch(logOut(props.history))}>Logout</a>
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownMenu> : <DropdownMenu>
                                        <div className="inner_head_sec">
                                            <div className="inner_heads">
                                                <div className="cancel_headsec">
                                                    <span className="drop-profile">
                                                        <div className="profile-img">{displayName}</div>
                                                        <span className="drop-user">
                                                            <span>{longName}</span>
                                                            <span className="drop-email">
                                                                {myProfile.email ? myProfile.email : null}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownMenu>}
                                </Dropdown>
                                {/* <Dropdown isOpen={dropdownOpen}>
                                    <DropdownToggle tag="div" className="profile-img" data-toggle={dropdownOpen}										>
                                        {displayName}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div className="inner_head_sec">
                                            <div className="inner_heads">
                                                <div className="cancel_headsec">
                                                    <span className="drop-profile">
                                                        <div className="profile-img">{displayName}</div>
                                                        <span className="drop-user">
                                                            <span>{longName}</span>
                                                            <span className="drop-email">
                                                                {myProfile.email ? myProfile.email : null}
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <a className="list_items" onClick={(e) => myProfilefun("myprofile")}>My Profile</a>
                                                    {String(active_subs) !== String(false) ?
                                                        (sessionStorage.getItem('userRole') === 'user') ? (
                                                            (user_permission.filter(e => String(e.name) === String('Settings') || String(e.name) === String('Settings')).length > 0) ?
                                                                <div className="navbar-dropdown" style={{ width: "100%" }}>
                                                                    <div className="nested navbar-item dropdown">
                                                                        <div className="dropdown-trigger">
                                                                            <a className="button list_items" aria-haspopup="true" aria-controls="dropdown-menu">
                                                                                <span className="icon-show">Settings</span>
                                                                            </a>
                                                                        </div>
                                                                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                                            <div className="dropdown-content">
                                                                                <a onClick={() => myProfilefun("companydetail")} className="dropdown-item list_items">Organisation Details</a>
                                                                                <a className="dropdown-item list_items" onClick={(e) => company_settings()}>Other Settings</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        ) : <div className="navbar-dropdown" style={{ width: "100%" }}>
                                                            <div className="nested navbar-item dropdown">
                                                                <div className="dropdown-trigger">
                                                                    <a className="button list_items" aria-haspopup="true" aria-controls="dropdown-menu">
                                                                        <span className="icon-show">Settings</span>
                                                                    </a>
                                                                </div>
                                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                                    <div className="dropdown-content">
                                                                        <a onClick={() => myProfilefun("companydetail")} className="dropdown-item list_items">Organisation Details</a>
                                                                        <a className="dropdown-item list_items" onClick={(e) => company_settings()}>Other Settings</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {(sessionStorage.getItem('userRole') === 'user')
                                                        ? (user_permission.filter(e => String(e.name) === String('Subscriptions') || String(e.name) === String('Subscriptions')).length > 0)
                                                            ? <a className="list_items" onClick={(e) => subscriptionList()}>Subscription</a>
                                                            : null
                                                        : <a className="list_items" onClick={(e) => subscriptionList()}>Subscription</a>}

                                                    {(sessionStorage.getItem('userRole') === 'company' && myProfile && myProfile.settings && myProfile.settings.showPoliciesonSite)
                                                        ? <a className="list_items" onClick={(e) => props.history.push('/published/policies')}>Public Documents</a>
                                                        : null}
                                                    {String(active_subs) !== String(false) && docUpdateStatus && docUpdateStatus === 'active'
                                                        ? <a className="list_items" onClick={() => restore_modal_view()}>Restore All</a>
                                                        : null}
                                                    <a className="logout-menu" onClick={() => dispatch(logOut(props.history))}>Logout</a>
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown> */}
                            </div>
                        )}
                    </div>
                </div>
                {/* {showMsg ?
                    <ReactstrapModal isOpen={showMsg} className='sub-expiry-modal'>
                        <ModalBody>
                            <p>Your subscription is not valid</p>
                            <span><img src="/assets/images/new-ui/subscription.svg" /></span>
                            <p className='d-a-btn'>
                                <button className='d-a-btn-update' onClick={() => history.push({ pathname: '/subscriptions', state: { active_tab: '2' } })}>Subscribe Now</button>
                            </p>
                        </ModalBody>
                    </ReactstrapModal> : null} */}
                {showRestoreModal && <RestoreModal history={props.history} restoreFunction={onRestoreAllDocuments} />}
                {pop_show_hide && <BottomModal history={props.history} />}
                {showNotification && <NotificationModal history={props.history} />}
                {showOneNotification && <OneNotificationModal />}
                {subdomainRemindModal && <SubdomainRemind history={props.history} />}
            </section>
        );
    } else {
        return null;
    }
}





const ResendCounter = ({ initialCount = 0, render = () => { }, start = false, interval = 60000, onStop = () => { } }) => {

    const [count, setCount] = useState(initialCount);
    let intervalRef;

    useEffect(() => {
        if (!start) return;
        intervalRef = setInterval(() => {
            if (count <= 0) {
                setCount(initialCount)
                onStop(false)
            }
            setCount((c) => c - 1)
        }, interval);

        return () => {
            clearInterval(intervalRef)
        }
    }, [start, count])




    return render ? render({ count }) : count
}


export default Header;